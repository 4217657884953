import {
  RenderService,
  SystemService,
  DebugFlags,
  GameInfoService,
  PhysicsService,
  VarService,
  DebugService,
  DQ
} from 'three-default-cube';
import { GlassShader } from './shaders/glass';
import { ConveyorBeltSurface } from './surfaces/conveyor-belt';
import { DeathPlateSurface } from './surfaces/death-plate';
import { FloorTriggerSurface } from './surfaces/floor-trigger';
import { LavaSurface } from './surfaces/lava';
import { changeView } from './utils/view';
import { inject } from '@vercel/analytics';

inject();

// if (typeof window !== 'undefined' && window.location.search.includes('debug')) {
  // DebugService.on(DebugFlags.DEBUG_ENABLE);
  // DebugService.on(DebugFlags.DEBUG_LIVE);
  // DebugService.on(DebugFlags.DEBUG_LOG_ASSETS);
  // DebugService.on(DebugFlags.DEBUG_LOG_MEMORY);
  // DebugService.on(DebugFlags.DEBUG_LOG_POOLS);
  // DebugService.on(DebugFlags.DEBUG_STORAGE);
  // DebugService.on(DebugFlags.DEBUG_TIME_LISTENERS);
  // DebugService.on(DebugFlags.DEBUG_NETWORK);
  // DebugService.on(DebugFlags.DEBUG_PHYSICS);
// }

(async () => {
  await VarService.registerPersistentVar('lastLevel', 'intro');

  if (VarService.getVar('lastLevel') === 'level11') {
    VarService.setVar('lastLevel', 'intro');
  }
  
  VarService.setVar('lastLevel', 'intro');

  GameInfoService
    .system(
      61,
      window.devicePixelRatio,
      1,
      1,
      0x000000,
      true,
    )
    .shadows(DQ.ShadowsStaticObjects, 32, 32, 1.0)
    .camera(50, 0.1, 1000.0)
    .texture('spinner', require('./assets/ui/spinner-default.png'))
    .font('default', require('./assets/ui/font.ttf'))
    .shader('glass', GlassShader)
    .vars({
      dialogueText: '',
      cutscene: false,
      playerDead: false,
      restartLevel: false
    });

  PhysicsService.registerSurfaceHandler('floorTrigger', FloorTriggerSurface);
  PhysicsService.registerSurfaceHandler('deathPlate', DeathPlateSurface);
  PhysicsService.registerSurfaceHandler('lava', LavaSurface);
  PhysicsService.registerSurfaceHandler('conveyorBelt', ConveyorBeltSurface);

  window.addEventListener('contextmenu', (event) => event.preventDefault());

  SystemService.init();
  SystemService.onReady(async () => {
    const rootElement = document.querySelector('#root');

    RenderService.init({ domElement: rootElement });

    changeView('menu');
    // changeView('game');

    // setInterval(() => changeView('menu', true), 5000);
    RenderService.run();
  });
})();
