import { Three, AssetsService, GameObjectClass, TimeService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { triggersMet } from '../utils/triggers';

export class LaserBeamGameObject extends GameObjectClass {
  target = null;

  electricity = [];
  deathPlate = null;

  constructor(target) {
    super();

    this.target = target;

    target.traverse(child => {
      if (child.userData.surface === 'deathPlate') {
        this.deathPlate = child;
      }

      if (child.userData.gameObject === 'electricity') {
        this.electricity.push(child);
      }
    });

    this.onCreate();
  }

  onCreate() {
    const target = this.target;
    const { triggerId } = target.userData;

    const frameListener = TimeService.registerFrameListener(() => {
      const triggerEnabled = triggersMet(triggerId);

      if (triggerEnabled) {
        this.electricity.forEach(object => {
          object.visible = false;
        });

        if (this.deathPlate.userData?.surfaceRef) {
          this.deathPlate.userData.surfaceRef.active = false;
        }
      } else {
        this.electricity.forEach(object => {
          object.visible = true;
        });

        if (this.deathPlate.userData?.surfaceRef) {
          this.deathPlate.userData.surfaceRef.active = true;
        }
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }
}
