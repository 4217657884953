import { WorldService } from "../services/world-service";

export class DeathPlateSurface {
  triggerTimer = null;
  active = true;

  constructor(target) {
    this.target = target;

    target.visible = false;
  }

  onEnter({ body }) {
    const isPlayer = body.target === WorldService.getPlayer()?.target;

    if (!isPlayer || this.triggerTimer || !this.active) {
      return;
    }

    this.triggerTimer = setTimeout(() => {
      WorldService.getPlayer().die();

      this.triggerTimer = null;
    }, 300);
  }

  onLeave() {
    if (!this.triggerTimer) {
      return;
    }

    clearInterval(this.triggerTimer);

    this.triggerTimer = null;
  }
}
