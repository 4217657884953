import { Three, TroikaText, AssetsService, AudioChannelEnums, AudioService, convertMaterialType, GameObjectClass, mathPi2, mathPi4, MathService, MathUtils, ParserService, RenderService, TimeService, UiService, UtilsService, VarService } from 'three-default-cube';
import UiModel from '../assets/models/ui.glb';
import { CutsceneService } from '../services/cutscene-service';
import hdriTexture from '../assets/textures/env.hdr';
import { MenuButtonGameObject } from './menu-button';
import { GameView } from '../views/game-view';

import Speech3SFX from '../assets/audio/speech-3.mp3';
import ToggleSFX from '../assets/audio/toggle.mp3';
import { changeView } from '../utils/view';
import { correctMaterials } from '../utils/materials';
import { WorldService } from '../services/world-service';

const { getSelectionRects } = TroikaText;

export class UiGameObject extends GameObjectClass {
  audioSpeech = null;
  audioCancelInterval = null;

  constructor() {
    super();

    UiService.registerUiElement(this);

    const hemisphereLight = new Three.HemisphereLight(0xbbbbff, 0x5555ff, 4.0);
    UiService.uiScene.add(hemisphereLight);

    this.onCreate();
  }

  onCreate() {
    const scene = RenderService.getScene();
    const camera = RenderService.getNativeCamera();

    AssetsService.getAudio(Speech3SFX).then(audio => {
      AudioService.setAudioVolume(audio, 0.0);
      AudioService.playAudio(25, audio, true);

      this.audioSpeech = audio;
    });

    AssetsService.getModel(UiModel).then(uiModel => {
      uiModel.visible = false;

      correctMaterials(uiModel, 'basic');

      ParserService.parseModel({
        target: uiModel,
        gameObjects: {
          dialogueBox: (target) => {
            let visible = false;

            target.scale.setScalar(0.5);

            VarService.getVar('dialogueText', (value) => {
              visible = !!value;

              AudioService.setAudioVolume(this.audioSpeech, visible ? 1.0 : 0.0);
              
              if (this.audioCancelInterval) {
                clearTimeout(this.audioCancelInterval);
              }

              this.audioCancelInterval = setTimeout(() => {
                AudioService.setAudioVolume(this.audioSpeech, 0.0);
              }, 1000);
            });

            TimeService.registerFrameListener(({ elapsedTime }) => {
              const lerpScale = MathUtils.lerp(target.scale.x, visible ? 1.0 : 0.5, 0.1);

              target.scale.setScalar(lerpScale);

              if (lerpScale > 0.8) {
                target.rotation.z = Math.sin(elapsedTime) * 0.025;
                target.rotation.x = Math.sin(elapsedTime) * 0.1;
              }
              
              target.visible = target.scale.x > 0.8;
            });
          },
          dialogueText: (target) => {
            VarService.getVar('dialogueText', value => {
              const lines = value.split('\n');

              target.position.y = -0.1 + 0.1 * lines.length;
            });
          },
          consoleLight: (target) => {
            let material = null;

            target.traverse(child => {
              if (child.material) {
                if (!material) {
                  material = child.material.clone();
                  material.map = AssetsService.cloneTexture(material.map);
                  material.emissive = new Three.Color(0xffffff);
                  material.emissiveIntensity = 1.0;
                  material.emissiveMap = material.map;
                }

                child.material = material;
              }
            });

            TimeService.registerFrameListener(({ elapsedTime }) => {
              const dt = Math.sin(elapsedTime / 2.0) * 0.5 + 0.5;

              material.emissiveIntensity = dt > 0.75 ? 1.0 : 0.0;
            });
          },
          retryBox: (target) => {
            let visible = false;

            target.scale.setScalar(0.5);

            VarService.getVar('playerDead', (value) => {
              visible = !!value;
            });

            TimeService.registerFrameListener(({ elapsedTime }) => {
              const lerpScale = MathUtils.lerp(target.scale.x, visible ? 1.0 : 0.5, 0.1);

              target.scale.setScalar(lerpScale);
              
              target.visible = target.scale.x > 0.8;
            });
          },
          retryButton: (target) => {
            target.userData.buttonRef = new MenuButtonGameObject(target);
          },
          catSkull: (target) => {
            TimeService.registerFrameListener(({ elapsedTime }) => {
              target.rotation.z = (Math.sin(elapsedTime) * 0.5 + 0.5) * 0.2 + 0.3;
              target.rotation.y = (Math.sin(elapsedTime * 0.5) * 0.5 + 0.5) * 0.2 - 1.3;
            });
          },
          catSkullJaw: (target) => {
            TimeService.registerFrameListener(({ elapsedTime }) => {
              target.rotation.z = (Math.sin(elapsedTime) * 0.5 + 0.5) * 0.4 - 0.5;
            });
          },
          loadingBox: (target) => {
            VarService.getVar('isLoading', (value) => {
              target.visible = value;
            });
          },
          playerBoundUi: (target) => {
            const { type } = target.userData;
            scene.add(target);

            const material = target.material;
            target.material = material;
            material.opacity = 0.0;
            material.transparent = true;

            let initializedDirection = false;

            TimeService.registerFrameListener(() => {
              const player = WorldService.getPlayer();
              const { interactionActive, interactionPosition } = WorldService;

              if (player && !initializedDirection) {
                target.position.copy(player.target.position);
                target.quaternion.copy(player.target.quaternion);
                initializedDirection = true;
              }

              if (!player || (type === 'activePointer' && !interactionActive) || VarService.getVar('cutscene')) {
                material.opacity = MathUtils.lerp(material.opacity, 0.0, 0.1);
                return;
              } else {
                material.opacity = MathUtils.lerp(material.opacity, 1.0, 0.1);
              }

              target.position.lerp(player.target.position, 0.1);

              const lookAtTarget = interactionPosition.clone();
              const mock = UtilsService.getEmpty();
              mock.position.copy(target.position);
              mock.quaternion.copy(target.quaternion);

              lookAtTarget.y = target.position.y;
              mock.lookAt(lookAtTarget);

              target.quaternion.slerp(mock.quaternion, 0.1);

              UtilsService.releaseEmpty(mock);
            });
          },
          proximityIndicator: (target) => {
            target.visible = false;

            this.proximityIndicators = Array(8).fill(0).map(_ => {
              const pivot = new Three.Group();
              const rotationPivot = new Three.Group();
              const cloned = target.clone();
              
              cloned.visible = true;
              cloned.material = convertMaterialType(cloned.material, 'basic');

              pivot.add(rotationPivot);
              rotationPivot.add(cloned);

              rotationPivot.position.set(0.0, 0.0, 4.0);
              cloned.position.set(0.0, 0.0, 0.0);

              scene.add(pivot);
              pivot.visible = false;

              return pivot;
            });
            this.proximityIndicators.forEach(item => AssetsService.registerDisposable(item));

            TimeService.registerFrameListener(() => {
              const { interactionPoints } = WorldService;

              this.proximityIndicators.forEach((indicator, index) => {
                if (!interactionPoints[index]) {
                  indicator.visible = false;
                  return;
                }

                indicator.position.copy(interactionPoints[index]);
                indicator.visible = true;

                const lookAtTarget = new Three.Vector3();
                camera.getWorldPosition(lookAtTarget);
                indicator.lookAt(lookAtTarget);
                indicator.children[0].lookAt(lookAtTarget);

                const player = WorldService.getPlayer();

                if (!player) {
                  return;
                }

                const playerPosition = MathService.getVec3();
                player.target.getWorldPosition(playerPosition);

                const proximityDistance = playerPosition.distanceToSquared(interactionPoints[index]);
                const proximityScale = MathUtils.mapLinear(MathUtils.clamp(proximityDistance, 5.0, 150.0), 5.0, 150.0, 1.0, 0.0);
                indicator.children[0].children[0].scale.setScalar(MathUtils.clamp(proximityScale, 0.2, 1.0));

                indicator.children[0].children[0].rotateZ(-0.1 * proximityScale);

                const material = indicator.children[0].children[0].material;

                if (proximityScale >= 0.9) {
                  material.color.lerp(new Three.Color(0x00ff00), 0.3);
                } else {
                  material.color.lerp(new Three.Color(0xffffff), 0.3);
                }

                if (proximityScale > 0.1) {
                  material.opacity = MathUtils.lerp(material.opacity, 1.0, 0.3);
                } else {
                  material.opacity = MathUtils.lerp(material.opacity, 0.0, 0.3);
                }

                MathService.releaseVec3(playerPosition);
              });
            });
          }
        },
        actions: {
          dialogueNext: (target, { stopPropagation }) => {
            stopPropagation();

            if (CutsceneService.activeDialoguePromise) {
              CutsceneService.activeDialoguePromise();
            }
          },
          retry: (target, { stopPropagation }) => {
            stopPropagation();

            AssetsService.getAudio(ToggleSFX).then(audio => {
              AudioService.setChannelVolume(AudioChannelEnums.globalChannel, 0.4);
              AudioService.playAudio(AudioChannelEnums.globalChannel, audio);

              VarService.setVar('playerDead', false);

              WorldService.setPlayer(null);
              WorldService.setPlayerCharacter(null);
              changeView('game', true);
            });
          }
        },
        onCreate: () => {
          this.add(uiModel);

          uiModel.traverse(child => {
            if (child.isMesh) {
              child.castShadow = false;
              child.receiveShadow = false;
            }
          });

          uiModel.visible = true;
        }
      });
    });
  }
}