import { Three, AssetsService, UtilsService } from "three-default-cube/three-default-cube";

export const getGeometryHash = (geometry) => {
  if (!geometry?.attributes?.position || !geometry?.attributes?.uv) {
    return;
  }

  return [
    geometry.attributes.position.count,
    ...(Array(10).fill(0).map((_, index) => {
      return geometry.attributes.position.array[(index * 5) % geometry.attributes.position.count];
    })),
    ...(Array(10).fill(0).map((_, index) => {
      return geometry.attributes.uv ? geometry.attributes.uv.array[(index * 2) % geometry.attributes.uv.count] : 0.0;
    }))
  ].map(code => `${code}`.split('.').slice(-1)[0].substr(0, 6))
  .filter(Boolean)
  .join(':');
};

export const mergeNavmaps = (tileModel) => {
  const mergedBodies = {};

  tileModel.traverse(child => {
    if (!child.userData.navmap) {
      return;
    }

    const hash = getGeometryHash(child.geometry);

    if (!hash) {
      return;
    }

    const mergeId = `${child.material.name}:${hash}`;

    if (!mergedBodies[mergeId]) {
      mergedBodies[mergeId] = [];
    }

    mergedBodies[mergeId].push(child);
  });

  const mock = UtilsService.getEmpty();

  Object.entries(mergedBodies).forEach(([ mergeId, bodies ]) => {
    const mergedMesh = new Three.InstancedMesh(
      bodies[0].geometry.clone(),
      bodies[0].material.clone(),
      // new Three.MeshToonMaterial({ color: Math.random() * 0x888888 + 0x888888 }),
      bodies.length
    );
    tileModel.add(mergedMesh);
    mergedMesh.userData.navmap = true;

    AssetsService.registerDisposeCallback(tileModel, () => {
      AssetsService.disposeAsset(mergedMesh);
    });

    bodies.forEach((body, index) => {
      body.getWorldPosition(mock.position);
      body.getWorldQuaternion(mock.quaternion);

      mock.updateMatrix();

      mergedMesh.setMatrixAt(index, mock.matrix);
      
      body.removeFromParent();
      AssetsService.disposeAsset(body);
    });

    AssetsService.registerDisposable(mergedMesh);
  });

  UtilsService.releaseEmpty(mock);
};

export const mergeObjects = (tileModel) => {
  const mergedBodies = {};

  tileModel.traverse(child => {
    const mergable = child.userData.physicsStatic && child.visible;

    if (!mergable) {
      return;
    }

    const hash = getGeometryHash(child.geometry);

    if (!hash) {
      return;
    }

    const mergeId = `${child.material.name}:${hash}`;

    if (!mergedBodies[mergeId]) {
      mergedBodies[mergeId] = [];
    }

    mergedBodies[mergeId].push(child);
  });

  const mock = UtilsService.getEmpty();

  Object.entries(mergedBodies).forEach(([ mergeId, bodies ]) => {
    if (bodies.length <= 1) {
      return;
    }

    const { geometry, material } = bodies.filter(Boolean).find(body => !!body.geometry) || {};

    if (!geometry || !material) {
      return;
    }

    const mergedMesh = new Three.InstancedMesh(
      geometry.clone(),
      material.clone(),
      // new Three.MeshToonMaterial({ color: Math.random() * 0x888888 + 0x888888 }),
      bodies.length
    );
    mergedMesh.name = mergeId;
    mergedMesh.castShadow = true;
    mergedMesh.receiveShadow = true;
    mergedMesh.material.side = Three.FrontSide;

    tileModel.add(mergedMesh);

    AssetsService.registerDisposeCallback(tileModel, () => {
      AssetsService.disposeAsset(mergedMesh);
    });

    bodies.forEach((body, index) => {
      if (!body || AssetsService.isDisposed(body) || !body.isMesh) {
        return;
      }

      body.getWorldPosition(mock.position);
      body.getWorldQuaternion(mock.quaternion);
      body.getWorldScale(mock.scale);

      mock.updateMatrix();

      mergedMesh.setMatrixAt(index, mock.matrix);
      
      body.removeFromParent();
      AssetsService.disposeAsset(body);
    });

    AssetsService.registerDisposable(mergedMesh);
  });

  UtilsService.releaseEmpty(mock);
};

export const mergeGeometries = (tileModel) => {
};
