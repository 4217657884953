import { Three, convertMaterialType } from 'three-default-cube/three-default-cube';

export const correctMaterials = (model, materialType = 'lambert') => {
  model.traverse(child => {
    if (!child.material || !child.visible) {
      return;
    }

    child.material = convertMaterialType(child.material, materialType);
    child.material.side = Three.DoubleSide;

    if (materialType !== 'basic') {
      child.material.emissiveMap = child.material.map;
      child.material.emissiveIntensity = 0.0;
      child.material.lightMap = null;
      child.material.lightMapIntensity = 0.0;
      child.material.metalness = 0.0;
      child.material.roughness = 0.75;
    }
  });
};
