import { Three, Cannon, AssetsService, AudioService, createArrowHelper, GameObjectClass, mathPi2, MathService, MathUtils, TimeService, PhysicsService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { triggersMet } from '../utils/triggers';

export class ConveyorBeltSurface extends GameObjectClass {
  target = null;

  active = false;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  onCreate() {
    const target = this.target;
    let material = null;

    const { triggerId } = target.userData;

    target.userData.physicsKinematic = true;
    target.userData.physicsShape = 'box';

    WorldService.createPhysicalBody(target);

    this.target.traverse(child => {
      if (child.material) {
        if (!material) {
          material = AssetsService.cloneMaterial(child.material);
        }

        child.material = material;
      }
    });

    const offsetY = 0.0;

    material.map.offset.set(0.0, offsetY);

    const frameListener = TimeService.registerFrameListener(({ elapsedTime }) => {
      const triggerEnabled = triggersMet(triggerId);

      if (!triggerEnabled) {
        this.active = false;
        return;
      } else {
        this.active = true;
      }

      if (!material) {
        return;
      }

      const positionY = Math.round(((offsetY + elapsedTime) * 10000) / 1000) * -0.05;

      material.map.offset.set(0.0, positionY);
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }

  onInteraction({ body }) {
    if (!body || !this.active) {
      return;
    }

    const direction = MathService.getVec3();
    this.target.getWorldDirection(direction);
    direction.multiplyScalar(body.mass / 15.0);

    body.applyImpulse(new Cannon.Vec3(direction.z, direction.y + 0.01, -direction.x));

    MathService.releaseVec3(direction);
  }
}
