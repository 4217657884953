import {
  Three,
  ViewClass,
  AssetsService,
  RenderService,
  SceneService,
  AnimationService,
  CameraService,
  TimeService,
  AudioService,
  AudioChannelEnums,
  VarService,
  convertMaterialType,
} from 'three-default-cube';
import sceneModel from '../assets/models/menu-scene.glb';
import hdriTexture from '../assets/textures/env.hdr';
import { ForkliftGameObject } from '../game-objects/forklift';
import { WorldService } from '../services/world-service';
import { BoxGameObject } from '../game-objects/box';
import { BoxSocketGameObject } from '../game-objects/box-socket';
import { ElectricityGameObject } from '../game-objects/electricity';
import { WallTriggerGameObject } from '../game-objects/wall-trigger';
import { FloorTriggerGameObject } from '../game-objects/floor-trigger';
import { DoorGameObject } from '../game-objects/door';
import { LaserBeamGameObject } from '../game-objects/laser-beam';
import { ElevatorGameObject } from '../game-objects/elevator';
import MenuTheme from '../assets/audio/menu-theme.mp3';
import ToggleSFX from '../assets/audio/toggle.mp3';
import { MenuButtonGameObject } from '../game-objects/menu-button';
import { GameView } from './game-view';
import { changeView } from '../utils/view';
import { correctMaterials } from '../utils/materials';
import { Preloader } from '../game-objects/preloader';
import { mergeObjects } from '../utils/optimisation';

export class MenuView extends ViewClass {
  onCreate() {
    const scene = RenderService.getScene();

    WorldService.createPhysics();
    WorldService.createInteractions();

    const preloaderObject = new Preloader({
      requireAssets: [
        AssetsService.getModel(sceneModel),
        AssetsService.getHDRI(hdriTexture),
        AssetsService.preloadAudio(MenuTheme)
      ],
      onComplete: ([
        viewModel,
        hdri,
        menuThemeAudio
      ]) => {
        const hemisphereLight = new Three.HemisphereLight(0xbbbbff, 0x5555ff, 2.0);
        viewModel.add(hemisphereLight);

        AudioService.playAudio(AudioChannelEnums.ambientChannel, menuThemeAudio);
        AudioService.setChannelVolume(AudioChannelEnums.ambientChannel, 1.0);

        SceneService.parseScene({
          target: viewModel,
          gameObjects: {
            physics: (target) => {
              WorldService.createPhysicalBody(target);
            },
            forklift: (target) => {
              const forklift = new ForkliftGameObject(target);
              forklift.setPlayer();
            },
            box: (target) => {
              new BoxGameObject(target);
            },
            boxSocket: (target) => {
              new BoxSocketGameObject(target);
            },
            tree: (target) => {
              AnimationService.registerAnimation({
                target,
                randomSeed: Math.random() * 1000,
                onStep: ({ target, animationTime }) => {
                  target.rotation.x = Math.sin(animationTime) * -0.05;
                }
              });
            },
            floorTrigger: (target) => {
              new FloorTriggerGameObject(target);
            },
            wallTrigger: (target) => {
              new WallTriggerGameObject(target);
            },
            door: (target) => {
              new DoorGameObject(target);
            },
            electricity: (target) => {
              new ElectricityGameObject(target);
            },
            laserBeam: (target) => {
              new LaserBeamGameObject(target);
            },
            elevator: (target) => {
              new ElevatorGameObject(target);
            },
            consoleLight: (target) => {
              let material = null;

              target.traverse(child => {
                if (child.material) {
                  if (!material) {
                    material = child.material.clone();
                    material.map = AssetsService.cloneTexture(material.map);
                    material.emissive = new Three.Color(0xffffff);
                    material.emissiveIntensity = 1.0;
                    material.emissiveMap = material.map;
                  }

                  child.material = material;
                }
              });

              TimeService.registerFrameListener(({ elapsedTime }) => {
                const dt = Math.sin(elapsedTime / 2.0) * 0.5 + 0.5;

                material.emissiveIntensity = dt > 0.75 ? 1.0 : 0.0;
              });
            },
            startButton: (target) => {
              target.userData.buttonRef = new MenuButtonGameObject(target);
            },
            volumeButton: (target) => {
              target.userData.buttonRef = new MenuButtonGameObject(target);
            },
            rateButton: (target) => {
              target.userData.buttonRef = new MenuButtonGameObject(target);
            }
          },
          actions: {
            changeVolume: (target) => {
              const volumeOptions = [ 0.0, 1.0 ];
              const currentVolumeIndex = volumeOptions.indexOf(VarService.getVar('optionsAudioVolume'));
              const nextVolumeIndex = (currentVolumeIndex + 1) % volumeOptions.length;

              AssetsService.getAudio(ToggleSFX).then(audio => {
                AudioService.playAudio(AudioChannelEnums.globalChannel, audio);
              });

              const newVolume = volumeOptions[nextVolumeIndex];

              target.userData.buttonRef.setActive(newVolume === 0.0);

              setTimeout(() => {
                VarService.setVar('optionsAudioVolume', newVolume);

                AudioService.setMasterVolume(newVolume);

                AssetsService.getAudio(ToggleSFX).then(audio => {
                  AudioService.playAudio(AudioChannelEnums.globalChannel, audio);
                });
              }, 300);
            },
            startGame: (target) => {
              if (target.userData.buttonRef.active) {
                return;
              }

              target.userData.buttonRef.setActive(true);

              AssetsService.getAudio(ToggleSFX).then(audio => {
                AudioService.playAudio(AudioChannelEnums.globalChannel, audio);

                changeView('game');
              });
            },
            rateApp: (target) => {
              AssetsService.getAudio(ToggleSFX).then(audio => {
                AudioService.playAudio(AudioChannelEnums.globalChannel, audio);

                window.open('https://twitter.com/thefrontendcat', '_tab');

                target.userData.buttonRef.setActive(true);

                setTimeout(() => {
                  target.userData.buttonRef.setActive(false);
                }, 1000);
              });
            }
          },
          onCreate: () => {
            correctMaterials(viewModel, 'basic');

            CameraService.useGameObjectCamera(CameraService.getCamera('menu'), true);

            const camera = RenderService.getNativeCamera();
            const cameraPosition = camera.position.clone();

            // TimeService.registerFrameListener(({ elapsedTime }) => {
            //   camera.position.y = cameraPosition.y + Math.sin(elapsedTime) * 0.05;
            //   camera.position.x = cameraPosition.x + Math.sin(elapsedTime / 2.0) * 0.1;
            // });

            scene.add(viewModel);
          }
        });
      }
    });

    scene.add(preloaderObject);
  }
}