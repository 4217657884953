import { Three, AssetsService, AudioService, createArrowHelper, GameObjectClass, mathPi2, MathService, MathUtils, TimeService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { triggersMet } from '../utils/triggers';

export class BillboardGameObject extends GameObjectClass {
  target = null;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  onCreate() {
    const target = this.target;
    const { triggerId } = target.userData;

    let screen;
    let screenMaterial;
    let elapsedTime = 0.0;

    target.traverse(child => {
      if (child.userData.gameObject === 'screen') {
        screen = child;
        screenMaterial = AssetsService.cloneMaterial(screen.material);

        child.material = screenMaterial;
      }
    });

    if (!screen || !screenMaterial) {
      return;
    }

    const frameListener = TimeService.registerFrameListener(({ dt }) => {
      const triggerEnabled = triggersMet(triggerId);

      if (!triggerEnabled) {
        screenMaterial.color = new Three.Color(0x333333);
        screenMaterial.emissiveIntensity = 0.0;

        elapsedTime = 0.0;
      } else {
        screenMaterial.color = new Three.Color(0xffffff);
        screenMaterial.emissiveIntensity = (Math.sin(elapsedTime * 70.0) * 0.5 + 0.5) * (1.0 - elapsedTime) < 0.5 ? 1.0 : 0.0;

        elapsedTime += dt;
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }
}
