import { AssetsService, GameObjectClass, MathUtils, TimeService } from 'three-default-cube';

export class MenuButtonGameObject extends GameObjectClass {
  target = null;

  active = false;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  onCreate() {
    const originalPosition = this.target.position.clone();

    const frameListener = TimeService.registerFrameListener(() => {
      if (this.active) {
        this.target.position.y = MathUtils.lerp(this.target.position.y, originalPosition.y - 0.1, 0.3);
      } else {
        this.target.position.y = MathUtils.lerp(this.target.position.y, originalPosition.y, 0.3);
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }

  setActive(status) {
    this.active = status;
  }
}