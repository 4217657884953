import { Three, AssetsService, AudioService, convertMaterialType, GameObjectClass, getRandomElement, math2Pi, mathPi2, mathPi4, MathService, ParserService, ParticleService, RenderService, TimeService } from 'three-default-cube';
import Scene from 'scenejs';

import HampsterLove1SFX from '../assets/audio/hampster-1.mp3';
import HampsterLove2SFX from '../assets/audio/hampster-2.mp3';
import HampsterLove3SFX from '../assets/audio/hampster-3.mp3';

const { MathUtils } = Three;

export class HeartsVFX extends GameObjectClass {
  target = null;
  particle = null;
  particles = [];

  active = false;

  constructor(target) {
    super();

    this.target = new Three.Group();

    target.parent.add(this.target);
    this.target.position.copy(target.position);
    target.position.y += 0.5;

    this.particle = target;
    this.particle.material.depthWrite = false;
    this.particle.material.depthTest = false;

    target.parent.remove(target);

    this.particles = Array(3).fill(0).map(_ => this.spawnParticle());
  }

  spawnParticle() {
    const target = new Three.Group();
    target.add(this.particle.clone());

    AssetsService.registerDisposable(target);

    const getRandomDelay = () => Math.random() * 2.0 + 1.0;

    target.visible = false;
    target.delay = getRandomDelay();
    target.random = Math.random() * 10.0;

    const timeline = new Scene({
      heart: {
        0: {
          position: 0.0,
          scale: 0.0,
        },
        10: {
          scale: 1.5,
        },
        15: {
          scale: 1.0,
        },
        90: {
          scale: 1.0,
        },
        100: {
          position: 3.0,
          scale: 0.0
        }
      }
    }, {
      easing: 'ease-out',
      iterationCount: 1,
      duration: 1.0,
    });

    timeline.on('animate', ({ frames }) => {
      if (!target) {
        return;
      }

      target.position.y = frames.heart.get('position');
      target.scale.setScalar(frames.heart.get('scale'));
    });

    timeline.on('ended', () => {
      target.visible = false;
      target.delay = getRandomDelay();
    });

    const frameListener = TimeService.registerFrameListener(({ dt, elapsedTime }) => {
      if (!this.active) {
        return;
      }

      if (target.delay > 0.0) {
        target.delay -= dt;
      } else if (timeline.state.playState === 'paused') {
        timeline.play();
        this.target.getWorldPosition(target.position);
        target.position.x += MathUtils.randFloatSpread(0.5);
        target.position.y += MathUtils.randFloatSpread(0.5);
        target.position.z += MathUtils.randFloatSpread(0.5);
        target.visible = true;

        AssetsService.getAudio(getRandomElement([
          HampsterLove1SFX,
          HampsterLove2SFX,
          HampsterLove3SFX,
        ])).then(audio => {
          AudioService.setAudioVolume(audio, 0.2);
          AudioService.playAudio(16, audio);
        });
      }

      const lookAtTarget = MathService.getVec3();
      target.getWorldPosition(lookAtTarget);
      lookAtTarget.z += 2.0;

      target.lookAt(lookAtTarget);

      const time = elapsedTime + target.random;

      target.position.z += Math.sin(time) * 0.1;
      target.children[0].rotation.x = Math.sin(time * 2.0) * mathPi4;

      MathService.releaseVec3(lookAtTarget);
    });

    AssetsService.registerDisposeCallback(target, () => {
      timeline.off('ended');
      timeline.off('animate');

      TimeService.disposeFrameListener(frameListener);
    });

    RenderService.getScene().add(target);
  }
}