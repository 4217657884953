import { Three, RenderService, TimeService } from 'three-default-cube/three-default-cube';

export const GlassShader = () => {
  const shader = {
    uniforms: {
      vResolution: {
        value: new Three.Vector2(window.innerWidth, window.innerHeight)
      }
    },
    vertexShader: `
      #include <common>
      #include <normal_pars_vertex>
  
      void main() {
        #include <beginnormal_vertex>
        #include <morphnormal_vertex>
        #include <skinbase_vertex>
        #include <skinnormal_vertex>
        #include <defaultnormal_vertex>
        #include <normal_vertex>
  
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
    `,
    fragmentShader: `
      #include <normal_pars_fragment>
      
      uniform vec2 vResolution;
      uniform vec3 vColor;
      uniform float fOpacity;
      
      void main() {
        #include <normal_fragment_begin>

        float n = max(max(max(normal.x, normal.y), normal.z), 0.3);
        vec2 viewCoord = vec2(gl_FragCoord.x / vResolution.x, gl_FragCoord.y / vResolution.y);
        
        vec3 color = vec3(1.0, 1.0, 1.0);
        float opacity = 0.1;
        
        float distance = mod((viewCoord.x - viewCoord.y) * 200.0, 100.0);

        if (distance < 50.0) {
          color *= 1.5;
          opacity = max(0.1, abs(10.0 - distance) / 100.0) * 0.1;
        }

        color *= vec3(0.76, 0.92, 1.0);
        color.x = clamp(color.x, 0.0, 1.0);
        color.y = clamp(color.y, 0.0, 1.0);
        color.z = clamp(color.z, 0.0, 1.0);
  
        gl_FragColor = vec4(
          color.x,
          color.y,
          color.z,
          opacity
        );
      }
    `,
    transparent: true,
    side: Three.FrontSide
  };

  const adjustSize = () => {
    const renderer = RenderService.getRenderer();
    const pixelRatio = renderer.getPixelRatio();

    shader.uniforms.vResolution.value.set(
      window.innerWidth * pixelRatio,
      window.innerHeight * pixelRatio,
    );
  };

  window.addEventListener('resize', () => {
    adjustSize();
  });

  adjustSize();

  return shader;
};
