import { Three, AssetsService, AudioService, createArrowHelper, GameObjectClass, mathPi2, MathService, MathUtils, TimeService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { triggersMet } from '../utils/triggers';

import ElevatorSFX from '../assets/audio/elevator.mp3';

export class ElevatorGameObject extends GameObjectClass {
  target = null;

  audioElevator = null;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  onCreate() {
    AssetsService.getAudio(ElevatorSFX).then(audio => {
      this.audioElevator = audio;

      AudioService.setAudioVolume(audio, 0.0);
      AudioService.playAudio(11, audio, true);
    });

    const target = this.target;
    const { triggerId, timing, offset, silent } = target.userData;

    target.userData.physicsKinematic = true;
    target.userData.physicsShape = 'box';
    const physicsBody = WorldService.createPhysicalBody(target);

    let elapsedTime = -(timing || 0.0);

    const originalPosition = target.position.clone();
    const displacement = new Three.Vector3(0.0, 1.0, 0.0);
    displacement.applyQuaternion(target.quaternion);

    displacement.multiplyScalar(offset || 3.0);

    const frameListener = TimeService.registerFrameListener(({ dt }) => {
      const triggerEnabled = triggersMet(triggerId);
      const newPosition = MathService.getVec3();

      if (!triggerEnabled) {
        target.position.lerp(originalPosition, 0.1);
        AudioService.setAudioVolume(this.audioElevator, 0.0);

        elapsedTime = -(timing || 0.0);
      } else {
        if (elapsedTime >= 0.0) {
          newPosition.copy(displacement);
          newPosition.multiplyScalar(MathUtils.clamp((1.0 - Math.cos(elapsedTime)) * 0.8 - 0.25, 0.0, 1.0));
          newPosition.add(originalPosition);
          const delta = newPosition.distanceTo(target.position);

          AudioService.setAudioVolume(this.audioElevator, silent ? 0.0 : Math.min(0.4, delta * 10.0));

          target.position.copy(newPosition);
        }
        elapsedTime += dt;
      }

      physicsBody.position.copy(target.position);
      physicsBody.quaternion.copy(target.quaternion);

      MathService.releaseVec3(newPosition);
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }
}
