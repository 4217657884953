import { RenderService } from "three-default-cube/three-default-cube";
import { GameView } from "../views/game-view";
import { MenuView } from "../views/menu-view";

let currentView = null;

export const changeView = (nextView, resetView = false) => {
  if (!resetView && currentView === nextView) {
    return;
  }

  switch (nextView) {
    case 'menu':
      RenderService.renderView(new MenuView());
      break;
    case 'game':
      RenderService.renderView(new GameView());
      break;
  }

  currentView = nextView;
};
