import { Three, AssetsService, AudioChannelEnums, AudioService, GameObjectClass, InteractionEnums, InteractionsService, MathService, MathUtils, TimeService, UtilsService, PhysicsService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { triggersMet } from '../utils/triggers';

import DoorOpenSFX from '../assets/audio/door-open.mp3';
import DoorSFX from '../assets/audio/door.mp3';
import { DISABLE_CUTSCENES } from '../services/cutscene-service';

export class DoorGameObject extends GameObjectClass {
  target = null;
  doorBlades = [];
  doorLight = null;

  opened = false;

  audioDoor = null;

  constructor(target) {
    super();
    
    this.target = target;

    target.traverse(child => {
      if (child.userData.gameObject === 'doorBlade') {
        child.userData.originalPosition = child.position.y;
        child.userData.position = child.position.y;

        this.doorBlades.push(child);
      }

      if (child.userData.gameObject === 'doorLight') {
        child.material = new Three.MeshStandardMaterial({
          color: 0x000000,
          metalness: 1.0,
          roughness: 1.0,
          emissive: new Three.Color(0xff0011),
          emissiveIntensity: 10.0,
        });

        this.doorLight = child;
      }
    });

    this.onCreate();
  }

  onCreate() {
    AssetsService.getAudio(DoorSFX).then(audio => {
      this.audioDoor = audio;

      AudioService.setAudioVolume(audio, 0.0);
      AudioService.setAudioPlaybackRate(audio, 0.75);
      AudioService.playAudio(15, audio, true);
    });

    const target = this.target;
    const { triggerId, triggerOrder } = target.userData;
    const doorHeight = 5.5;

    target.userData.physicsShape = 'box';
    const doorBody = WorldService.createPhysicalBody(target);
    PhysicsService.makeStatic(doorBody);

    const frameListener = TimeService.registerFrameListener(({ elapsedTime }) => {
      if (!this.doorLight) {
        return;
      }

      const triggerEnabled = DISABLE_CUTSCENES ? true : triggersMet(triggerId, triggerOrder);

      if (triggerEnabled) {
        if (!this.opened) {
          AssetsService.getAudio(DoorOpenSFX).then(audio => {
            AudioService.setAudioVolume(audio, 0.1);
            AudioService.playAudio(AudioChannelEnums.globalChannel, audio);
          });

          this.opened = true;
        }

        this.doorBlades.forEach(blade => {
          if (blade.userData.position < doorHeight) {
            blade.userData.position += 0.075;

            AudioService.setAudioVolume(this.audioDoor, 0.2);
          } else {
            AudioService.setAudioVolume(this.audioDoor, 0.0);
          }

          blade.position.y = Math.min(doorHeight, blade.userData.position);
        });

        if (this.doorLight.material) {
          this.doorLight.material.emissive.set(0x00ff00);
        }

        PhysicsService.disableCollisions(doorBody);
      } else {
        this.doorBlades.forEach(blade => {
          if (blade.userData.position > blade.userData.originalPosition) {
            blade.userData.position -= 0.075;

            AudioService.setAudioVolume(this.audioDoor, 0.2);
          } else {
            AudioService.setAudioVolume(this.audioDoor, 0.0);
          }

          blade.position.y = Math.min(doorHeight, blade.userData.position);
        });

        if (this.doorLight.material) {
          this.doorLight.material.emissive.set(0xff0000);
        }

        PhysicsService.enableCollisions(doorBody);
      }

      if (this.doorLight.material) {
        this.doorLight.material.emissiveIntensity = (1.0 + Math.sin(elapsedTime) * 0.5 + 0.5) * 10.0;
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
      AudioService.stopAudio(this.audioDoor);
    });
  }
}
