import { Three, AssetsService, AudioChannelEnums, AudioService, GameObjectClass, InteractionEnums, InteractionsService, MathService, MathUtils, TimeService, UtilsService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import TriggerSFX from '../assets/audio/trigger.mp3';
import { CutsceneService } from '../services/cutscene-service';

export class WallTriggerGameObject extends GameObjectClass {
  target = null;

  constructor(target) {
    super();
    
    this.target = target;

    this.onCreate();
  }

  onCreate() {
    const target = this.target;
    const { triggerId, resetTriggerId, cutscene } = target.userData;

    const targetWorldPosition = new Three.Vector3();
    target.getWorldPosition(targetWorldPosition);

    WorldService.registerInteractionPoint(targetWorldPosition);
    AssetsService.registerDisposeCallback(target, () => WorldService.disposeInteractionPoint(targetWorldPosition));

    target.children.forEach(child => {
      if (child.material) {
        child.material.transparent = true;
        child.material.opacity = 0.0;
        child.material.depthWrite = false;
        child.material.depthTest = false;
        child.castShadow = false;
      }
    });

    InteractionsService.registerListener(target.children[0] || target, [ InteractionEnums.eventClick ], () => {
      const player = WorldService.getPlayer();

      if (!player) {
        return;
      }

      const { target: playerForklift } = player;

      const forkliftPosition = MathService.getVec3();
      const triggerPosition = MathService.getVec3();

      playerForklift.getWorldPosition(forkliftPosition);
      target.getWorldPosition(triggerPosition);

      const distance = MathService.getVec2(
        forkliftPosition.x - triggerPosition.x,
        forkliftPosition.z - triggerPosition.z,
      );
      const distanceToForklift = distance.lengthSq();
      const isWithinReach = distanceToForklift <= 25.0;
      
      const triggered = WorldService.triggers[triggerId || `cutscene_${cutscene}`];

      if (isWithinReach && !triggered) {
        if (cutscene) {
          WorldService.triggers[`cutscene_${cutscene}`] = true;
    
          CutsceneService.startCutscene(cutscene);
        } else {
          WorldService.triggers[triggerId] = true;
        }

        WorldService.disposeInteractionPoint(targetWorldPosition);

        if (typeof resetTriggerId !== 'undefined') {
          WorldService.triggers[resetTriggerId] = false;
        }

        AssetsService.getAudio(TriggerSFX).then(audio => {
          AudioService.setAudioVolume(audio, 0.4);
          AudioService.playAudio(AudioChannelEnums.globalChannel, audio);
        });
      }
    });

    const triggerPosition = target.position.z;

    const frameListener = TimeService.registerFrameListener(() => {
      const triggerEnabled = WorldService.triggers[triggerId];

      if (triggerEnabled) {
        target.position.z = MathUtils.lerp(target.position.z, triggerPosition + 0.135, 0.1);
      } else {
        target.position.z = MathUtils.lerp(target.position.z, triggerPosition, 0.1);
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      InteractionsService.disposeListener(this.target);
      TimeService.disposeFrameListener(frameListener);
    });
  }
}
