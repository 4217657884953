import { Three, AssetsService, convertMaterialType, GameObjectClass, math2Pi, mathPi2, ParserService, RenderService, TimeService } from 'three-default-cube';
import Scene from 'scenejs';

import ExplosionModel from '../assets/models/explosion-vfx.glb';

export class ExplosionVFX extends GameObjectClass {
  timeline = null;

  constructor(target) {
    super();

    this.onCreate();

    AssetsService.getModel(ExplosionModel).then(model => {
      ParserService.parseModel({
        target: model,
        gameObjects: {
          ray: (target) => {
            for (let i = 0; i < 6; i++) {
              this.createRay(target);
            }
          },
          fireBall: (target) => {
            this.createFireball(target);
          },
          smokeBall: (target) => {
            for (let i = 0; i < 5; i++) {
              this.createSmoke(target);
            }
          }
        },
        onCreate: () => {
          this.position.copy(target.position);
          target.parent.add(this);

          this.timeline.on('ended', () => {
            if (this.parent) {
              this.parent.remove(this);
            }

            this.timeline.off('animate');
          });

          this.timeline.play();
        }
      })
    });
  }

  onCreate() {
    this.timeline = new Scene({
      fireBall: {
        0: {
          scale: 0.5,
          opacity: 1.0,
          positionY: 0.0
        },
        2: {
          scale: 0.1,
          opacity: 1.0,
          positionY: 0.0
        },
        5: {
          scale: 1.5,
          opacity: 1.0,
          positionY: 0.1,
        },
        10: {
          scale: 2.0,
          opacity: 1.0,
          positionY: 0.2
        },
        50: {
          scale: 0.9,
          opacity: 0.0,
          positionY: 1.0
        }
      },
      rays: {
        0: {
          opacity: 0.0
        },
        2: {
          scale: 0.1,
          opacity: 1.0
        },
        3: {
          scale: 2.0,
          opacity: 1.0
        },
        5: {
          scale: 4.0,
          opacity: 0.0
        }
      },
      blast: {
        0: {
          opacity: 0.0
        },
        4: {
          opacity: 1.0,
          scale: 0.5,
        },
        5: {
          opacity: 1.0,
          scale: 2.0
        },
        20: {
          scale: 4.0,
          opacity: 0.0,
        }
      },
      smoke: {
        0: {
          opacity: 0.0,
          scale: 0.7
        },
        5: {
          opacity: 0.0,
          scale: 0.7
        },
        10: {
          opacity: 1.0,
          scale: 1.5
        },
        50: {
          opacity: 0.0,
          scale: 4.0
        }
      }
    }, {
      easing: 'ease-out',
      fillMode: 'both',
      iterationCount: 1,
      duration: 3.0,
    });
  }

  createRay(base) {
    const model = base.clone();

    model.material = convertMaterialType(model.material, 'basic');
    model.material.side = Three.DoubleSide;
    model.material.depthWrite = false;

    model.scale.setScalar(Math.random() * 1.0 + 1.0);
    model.position.set(0.0, 0.0, 0.0);
    model.rotation.set(
      Math.random() * math2Pi,
      0.0,
      0.0
    );

    this.timeline.on('animate', ({ frames }) => {
      if (!model || !model.material) {
        return;
      }

      model.scale.setScalar(frames.rays.get('scale'));
      model.material.opacity = frames.rays.get('opacity');
    });

    this.add(model);
  }

  createFireball(base) {
    const model = base.clone();

    model.material = new Three.MeshBasicMaterial({
      color: 0xffffcc,
      transparent: true,
      opacity: 1.0
    });
    model.material.side = Three.DoubleSide;

    model.position.set(0.0, 0.0, 0.0);

    this.timeline.on('animate', ({ frames }) => {
      if (!model || !model.material) {
        return;
      }

      model.scale.setScalar(frames.fireBall.get('scale'));
      model.material.opacity = frames.fireBall.get('opacity');
      model.position.y = frames.fireBall.get('positionY');
    });

    this.add(model);
  }

  createSmoke(base) {
    const model = base.clone();

    model.material = new Three.MeshBasicMaterial({
      color: 0x111111,
      transparent: true,
      opacity: 1.0
    });
    model.material.side = Three.DoubleSide;

    const initialScale = Math.random() * 1.0 + 0.1;

    model.scale.setScalar(initialScale);
    model.position.set(
      Math.random() * 1.0 - 0.5,
      0.0,
      Math.random() * 1.0 - 0.5,
    );

    this.timeline.on('animate', ({ frames }) => {
      if (!model || !model.material) {
        return;
      }

      model.scale.setScalar(initialScale * frames.smoke.get('scale'));
      model.material.opacity = frames.smoke.get('opacity');
    });

    this.add(model);
  }
}