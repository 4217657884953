import { Three, AssetsService, GameObjectClass, InteractionEnums, InteractionsService, MathService, TimeService, UtilsService } from 'three-default-cube';
import { WorldService } from '../services/world-service';

export class BoxSocketGameObject extends GameObjectClass {
  target = null;

  socketMaterial = null;
  active = false;

  constructor(target) {
    super();

    // NOTE Placing boxes displaces them by 50% on y-axis and I'm lazy
    const pivot = new Three.Group();

    const box3 = UtilsService.getBox3();

    const targetSize = MathService.getVec3();
    box3.expandByObject(target);
    box3.getSize(targetSize);

    pivot.userData = target.userData;
    pivot.position.copy(target.position);
    pivot.position.y -= targetSize.y;
    pivot.quaternion.copy(target.quaternion);

    target.position.set(
      0.0,
      targetSize.y,
      0.0
    );
    target.quaternion.identity();

    target.parent.add(pivot);
    pivot.add(target);
    
    this.target = pivot;

    this.onCreate();

    AssetsService.registerDisposable(pivot);
    MathService.releaseVec3(targetSize);
    UtilsService.releaseBox3(box3);
  }

  onCreate() {
    const target = this.target;
    const { socketId, blind } = target.userData;

    this.socketMaterial = new Three.MeshBasicMaterial({
      color: ({
        0: 0xffffff,
        1: 0x0011ff,
        2: 0x00ff11,
        3: 0xff00cc,
        4: 0xffff00,
        5: 0x000000,
      })[target.userData.socketId],
      opacity: 0.1,
      transparent: true
    });

    if (blind) {
      this.socketMaterial.color.set(0xffffff);
    }

    target.traverse(child => {
      if (child.material) {
        child.material = this.socketMaterial;
      }
    });

    InteractionsService.registerListener(target, [ InteractionEnums.eventClick ], () => {
      const player = WorldService.getPlayer();

      if (!this.active || !player || !player.heldItem) {
        return;
      }

      const holdsMatchingItem = [].concat(target.userData.socketId).includes(player.heldItem.target.userData.boxId);

      if (holdsMatchingItem) {
        player.placeItem(this);

        this.target.visible = false;
        WorldService.triggers[socketId] = true;
      }
    });

    const frameListener = TimeService.registerFrameListener(({ elapsedTime }) => {
      this.onAllowPlacement({ elapsedTime });
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      InteractionsService.disposeListener(this.target);
      TimeService.disposeFrameListener(frameListener);
    });
  }

  onAllowPlacement({ elapsedTime }) {
    const target = this.target;
    const player = WorldService.getPlayer();

    if (!player) {
      return;
    }

    const { target: playerForklift, heldItem } = player;

    if (!heldItem) {
      this.active = false;
      this.socketMaterial.opacity = 0.1;

      return;
    }

    const holdsMatchingItem = [].concat(target.userData.socketId).includes(heldItem.target.userData.boxId);

    if (!holdsMatchingItem) {
      this.active = false;
      this.socketMaterial.opacity = 0.1;

      return;
    }

    const forkliftPosition = MathService.getVec3();
    const boxPosition = MathService.getVec3();

    playerForklift.getWorldPosition(forkliftPosition);
    target.getWorldPosition(boxPosition);

    const distance = MathService.getVec2(
      forkliftPosition.x - boxPosition.x,
      forkliftPosition.z - boxPosition.z,
    );
    const distanceToForklift = distance.lengthSq();
    const distanceToGrab = Math.abs(forkliftPosition.y - boxPosition.y);
    const isWithinReach = distanceToForklift <= 20.0 && distanceToGrab < 2.0;

    const directionA = MathService.getVec3();
    const directionB = MathService.getVec3();
    playerForklift.getWorldDirection(directionA).normalize();
    directionA.y = 0.0;

    directionB.copy(boxPosition).sub(forkliftPosition).normalize();
    directionB.y = 0.0;

    const isFacingTarget = directionA.dot(directionB) >= 0.9;

    if (isWithinReach && isFacingTarget) {
      this.active = true;
      this.socketMaterial.opacity = (Math.sin(elapsedTime * 2.0) * 0.5 + 0.5) * 0.5 + 0.1;
    } else {
      this.active = false;
      this.socketMaterial.opacity = 0.1;
    }

    MathService.releaseVec3(forkliftPosition);
    MathService.releaseVec3(boxPosition);
    MathService.releaseVec3(directionA);
    MathService.releaseVec3(directionB);
    MathService.releaseVec2(distance);
  }
}