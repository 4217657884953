import { Three, TimeService, RenderService, AssetsService, GameObjectClass } from 'three-default-cube';
import SpinnerModel from '../assets/models/spinner.glb';

export class Preloader extends GameObjectClass {
  spinnerTexture = null;

  constructor({ requireAssets, onComplete } = {}) {
    super();

    Promise.all([
      ...(requireAssets || []),
      TimeService.createTimeoutPromise(3000)
    ]).then(assets => {
      const complete = onComplete(assets);

      if (complete && complete.then) {
        complete.then(() => this.onLoaded());
      } else {
        this.onLoaded();
      }
    }).catch(error => {
      console.info('Preloader', 'error');
      throw error;
    });

    this.onCreate();
  }

  async onCreate() {
    GameObjectClass.prototype.onCreate.call(this);

    const camera = RenderService.getNativeCamera();

    const spinner = await AssetsService.getModel(SpinnerModel);
    spinner.rotation.x -= 0.5;
    spinner.scale.setScalar(0.1);
    spinner.name = 'spinner';

    this.add(spinner);

    this.position.z -= 5.0;
    this.lookAt(new Three.Vector3(0, 0, 0));

    TimeService.registerFrameListener(() => {
      const spinner = this.getObjectByName('spinner');

      if (!spinner || !camera) {
        return false;
      }

      spinner.rotation.y += 0.05;
    });

    camera.add(this);
  }

  onLoaded() {
    const camera = RenderService.getNativeCamera();
    const spinner = this.getObjectByName('spinner');

    if (spinner) {
      this.remove(spinner);

      AssetsService.disposeAsset(spinner);
    }
  }

  dispose() {
    GameObjectClass.prototype.dispose.call(this);
  }
}
