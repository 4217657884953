import { Three, AssetsService, AudioChannelEnums, AudioService, TimeService, VarService } from "three-default-cube/three-default-cube";
import { WorldService } from "../services/world-service";
import TriggerSFX from '../assets/audio/trigger.mp3';
import { CutsceneService } from "../services/cutscene-service";

export class FloorTriggerSurface {
  triggerTimer = null;

  activeCollisions = [];

  constructor(target) {
    const { nextLevel, startLevel, cutscene } = target.userData;

    this.target = target;

    if (nextLevel || startLevel || cutscene) {
      this.target.visible = false;
    }
  }

  onEnter({ body }) {
    const { nextLevel, startLevel, cutscene } = this.target.userData;

    this.target.traverse(child => {
      if (child.material) {
        child.material.color = new Three.Color(0xff00ff);
      }
    });

    this.changeActiveCollisions(1, body);

    if (this.triggerTimer) {
      return;
    }

    const isPlayer = body === WorldService.getPlayer()?.physicsBody;

    if ((nextLevel || startLevel || cutscene) && !isPlayer) {
      return;
    }

    this.triggerTimer = setTimeout(() => {
      this.toggle(true);

      if (typeof this.target.userData.resetTriggerId !== 'undefined') {
        WorldService.triggers[this.target.userData.resetTriggerId] = false;
      }

      this.triggerTimer = null;
    }, 0.0);
  }

  onLeave({ body }) {
    this.changeActiveCollisions(-1, body);

    if (this.triggerTimer) {
      clearInterval(this.triggerTimer);
      this.triggerTimer = null;
    }
  }

  changeActiveCollisions(enable, body) {
    const { weighted } = this.target.userData;

    if (enable === 1) {
      this.activeCollisions = this.activeCollisions.filter(match => match !== body);
      this.activeCollisions.push(body);
    } else if (enable === -1) {
      this.activeCollisions = this.activeCollisions.filter(match => match !== body);
    }

    if (this.activeCollisions.length === 0 && weighted) {
      this.toggle(false);
    }
  }

  toggle(active) {
    const { nextLevel, startLevel, cutscene } = this.target.userData;

    const current = WorldService.triggers[this.target.userData.triggerId || `cutscene_${cutscene}`];

    if (current === active) {
      return;
    }

    if (nextLevel) {
      WorldService.triggers = {};

      VarService.setVar('loadNextLevel', nextLevel);
    }

    if (cutscene) {
      WorldService.triggers[`cutscene_${cutscene}`] = active;

      CutsceneService.startCutscene(cutscene);
    } else {
      WorldService.triggers[this.target.userData.triggerId] = active;
    }

    if (this.target.visible) {
      AssetsService.getAudio(TriggerSFX).then(audio => {
        AudioService.setAudioVolume(audio, 0.4);
        AudioService.playAudio(AudioChannelEnums.globalChannel, audio);
      });
    }
  }
}
