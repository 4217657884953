
import { Three, AssetsService, GameObjectClass, MathService, MathUtils, ParserService, RenderService, TimeService } from 'three-default-cube';
import { triggersMet } from '../utils/triggers';

import ForkliftModel from '../assets/models/forklift.glb';
import { ForkliftGameObject } from './forklift';
import { WorldService } from '../services/world-service';
import { BobbleHeadGameObject } from './bobble-head';
import { BoxGameObject } from './box';
import { HampsterGameObject } from './hampster';
import { correctMaterials } from '../utils/materials';

export class SpawnGameObject extends GameObjectClass {
  target = null;

  constructor(target) {
    super();

    this.target = target;
    target.visible = false;

    this.onCreate();
  }

  spawnModel(modelId, userData, spawnPosition) {
    return new Promise(async (resolve) => {
      const model = await AssetsService.getModel(modelId);
      const scene = RenderService.getScene();

      correctMaterials(model);

      let onCreated = [];

      ParserService.parseModel({
        target: model,
        gameObjects: {
          forklift: (target) => {
            scene.add(target);
            target.position.copy(spawnPosition);
  
            const forklift = new ForkliftGameObject(target);
  
            if (!WorldService.getPlayer()) {
              forklift.setPlayer();
            }
          },
          bobbleHead: (target) => {
            scene.add(target);
  
            const bobbleHead = new BobbleHeadGameObject(target);
  
            if (!WorldService.getPlayerCharacter()) {
              bobbleHead.setPlayerCharacter();
            }
          },
          box: (target) => {
            // if (userData.withHampster) {
            //   scene.add(target);
            //   target.position.add(spawnPosition);
  
            //   const box = new BoxGameObject(target);

            //   onCreated.push(() => {
            //     setTimeout(() => {
            //       WorldService.getPlayer().pickUpItem(box);
            //     }, 1000);
            //   });
            // } else {
            //   AssetsService.disposeAsset(target);
            // }
          },
          hampster: (target) => {
            if (userData.withHampster) {
              scene.add(target);
  
              new HampsterGameObject(target);
            }
          },
        },
        onCreate: () => {
          onCreated.forEach(callback => callback());
          onCreated = [];

          resolve(model);
        }
      });
    });
  }

  onCreate() {
    const { spawnType } = this.target.userData;
    const spawnPosition = new Three.Vector3();
    this.target.getWorldPosition(spawnPosition);

    if (spawnType === 'forklift') {
      if (!WorldService.getPlayer()) {
        this.spawnModel(ForkliftModel, this.target.userData, spawnPosition);
      } else if (WorldService.getPlayer()?.isDead) {
        WorldService.getPlayer().target.position.copy(spawnPosition);
        WorldService.getPlayer().isDead = false;
      }
    }
  }
}
