import { AssetsService, GameObjectClass, InteractionEnums, InteractionsService, MathService, MathUtils, TimeService, UtilsService } from 'three-default-cube';
import { WorldService } from '../services/world-service';

export class FloorTriggerGameObject extends GameObjectClass {
  target = null;

  constructor(target) {
    super();
    
    this.target = target;

    this.onCreate();
  }

  onCreate() {
    const target = this.target;
    const { triggerId } = target.userData;

    const originalPosition = target.position.y;

    const frameListener = TimeService.registerFrameListener(() => {
      const triggerEnabled = WorldService.triggers[triggerId];

      if (triggerEnabled) {
        target.position.y = MathUtils.lerp(target.position.y, originalPosition, 0.1);
      } else {
        target.position.y = MathUtils.lerp(target.position.y, originalPosition + 0.3, 0.1);
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }
}
