import { Cannon, Three, AssetsService, AudioService, CameraService, createBoxHelper, GameObjectClass, MathService, MathUtils, PhysicsWrapper, RenderService, TimeService, UtilsService, VarService, PhysicsService, createDefaultCube } from 'three-default-cube';
import { SHOW_COLLISION_BOXES, WorldService } from '../services/world-service';

import WheelsSFX from '../assets/audio/wheels.mp3';
import YouDiedSFX from '../assets/audio/you-died.mp3';

import { ExplosionVFX } from '../game-vfx/explosion-vfx';
import { USE_SCENE_LIGHTS } from '../views/game-view';
import { correctMaterials } from '../utils/materials';

export class ForkliftGameObject extends GameObjectClass {
  target = null;
  fork = null;

  physicsBody = null;

  isPlayer = false;
  isDead = false;
  cutscene = false;
  forkLevel = 0.0;
  onForkLevelReached = null;
  heldItem = null;

  audioWheels = null;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  setPlayer() {
    this.isPlayer = true;

    WorldService.setPlayer(this);

    this.createTrackingLight();

    this.follow();

    if (WorldService.getPlayerCharacter()) {
      WorldService.getPlayerCharacter().driveForklift(); 
    }
  }

  createTrackingLight() {
    const scene = RenderService.getScene();

    const pointLight = new Three.PointLight(0xffedcc, 1.0, 100.0, 0.1);
    pointLight.position.y += 1.0;
    pointLight.position.z += 3.0;
    pointLight.shadow.mapSize.width = 512;
    pointLight.shadow.mapSize.height = 512;
    pointLight.shadow.radius = 8.0;
    pointLight.castShadow = true;

    scene.add(pointLight);

    const listener = TimeService.registerFrameListener(({ elapsedTime }) => {
      const position = MathService.getVec3();

      this.target.getWorldPosition(position);
      position.y += 3.0;
      position.y += Math.sin(elapsedTime * 0.1) * 0.5;
      pointLight.position.lerp(position, 0.1);

      MathService.releaseVec3(position);
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(listener);
    });
  }

  follow() {
    CameraService.useThirdPersonCamera(
      this.target,
      new Three.Vector3(15.0, 20.0, 0.0),
      false
    );

    // CameraService.cameraControls.mouseButtons.wheel = 0;

    CameraService.cameraControls.mouseButtons.left = 0;
    CameraService.cameraControls.mouseButtons.right = 0;
    CameraService.cameraControls.mouseButtons.middle = 0;
    CameraService.cameraControls.touches.one = 0;
    CameraService.cameraControls.touches.two = 0;
    CameraService.cameraControls.touches.three = 0;

    setTimeout(() => {
      CameraService.tween = 0.1;
    }, 300);
  }

  onCreate() {
    const target = this.target;

    const wheels = [];
    let rearWheel;
    let fork;
    let lightBulb;

    AssetsService.getAudio(WheelsSFX).then(audio => {
      this.audioWheels = audio;

      AudioService.setAudioVolume(audio, 0.0);
      AudioService.playAudio(10, audio, true);
    });

    target.traverse(child => {
      if (child.userData.gameObject === 'wheel') {
        wheels.push(child);
      }

      if (child.userData.gameObject === 'rearWheel') {
        rearWheel = child;
      }

      if (child.userData.gameObject === 'forkliftFork') {
        fork = child;
      }

      if (child.userData.gameObject === 'forkliftLight') {
        lightBulb = child;
      }
    });

    this.createFork(fork);

    target.quaternion.identity();
    
    const box3 = UtilsService.getBox3();
    const objectSize = new Three.Vector3();
    target.remove(fork);
    box3.expandByObject(target);
    box3.getSize(objectSize);
    target.add(fork);

    const { body } = new PhysicsWrapper(target, {
      physicsShape: 'box',
      physicsSize: objectSize.y,
      physicsStatic: false,
      physicsFriction: 0.0,
      physicsRestitution: 0.0,
      physicsWeight: 5.0,
      physicsDamping: 0.99,
      physicsCollisionGroup: -1,
    });
    this.physicsBody = body;

    lightBulb.material = lightBulb.material.clone();
    lightBulb.material.emissive = new Three.Color(0xff8c00);
    lightBulb.material.emissiveIntensity = 1.0;

    const frameListener = TimeService.registerFrameListener(({ dt, elapsedTime }) => {
      const isLoading = VarService.getVar('isLoading');

      wheels.forEach(wheel => {
        wheel.rotation.x += body.velocity.length() / 20.0;
      });

      target.position.copy(body.position);
      target.position.y -= objectSize.x / 2.0;
      target.position.y += objectSize.y / 2.0;

      target.quaternion.copy(body.quaternion);

      if (!this.isDead) {
        AudioService.setAudioVolume(this.audioWheels, (Math.sin(elapsedTime) * 0.4 + 0.6) * Math.min(body.velocity.length(), 0.1));
      } else {
        AudioService.setAudioVolume(this.audioWheels, 0.0);
      }

      if (this.cutscene || isLoading) {
        return;
      }

      if (WorldService.interactionActive) {
        body.applyLocalForce(
          new Cannon.Vec3(0.0, -1.0, 150.0)
          // new Cannon.Vec3(0.0, -1.0, 250.0)
        );
      } else {
        target.quaternion.z = MathUtils.lerp(target.quaternion.z, 0.0, 0.05);
        target.quaternion.x = MathUtils.lerp(target.quaternion.x, 0.0, 0.05);
        body.quaternion.copy(target.quaternion);
      }

      const interactionPosition = WorldService.interactionPosition;

      if (interactionPosition) {
        const mock = UtilsService.getEmpty();
        mock.position.copy(target.position);
        mock.quaternion.copy(target.quaternion);
        mock.lookAt(interactionPosition);

        const originalTargetQuaternion = MathService.getQuaternion();
        originalTargetQuaternion.copy(target.quaternion);

        target.quaternion.slerp(mock.quaternion, 0.03);
        target.quaternion.x = originalTargetQuaternion.x;
        target.quaternion.z = originalTargetQuaternion.z;
        body.quaternion.copy(target.quaternion);

        rearWheel.lookAt(interactionPosition);
        rearWheel.quaternion.invert();

        UtilsService.releaseEmpty(mock);
        MathService.releaseQuaternion(originalTargetQuaternion);
      }

      lightBulb.material.emissiveIntensity = (Math.sin(elapsedTime) * 0.5 + 0.5) * 2.0 + 1.0;
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });

    UtilsService.releaseBox3(box3);

    correctMaterials(target);

    target.traverse(child => {
      if (child.isMesh) {
        child.castShadow = false;
      }
    });
  }

  createFork(target) {
    const box3 = UtilsService.getBox3();

    const forkSize = MathService.getVec3();
    box3.expandByObject(target);
    box3.getSize(forkSize);

    this.fork = target;

    const frameListener = TimeService.registerFrameListener(({ elapsedTime }) => {
      target.position.y = MathUtils.lerp(target.position.y, this.forkLevel, 0.1);

      if (this.onForkLevelReached && Math.abs(target.position.y - this.forkLevel) < 0.01) {
        this.onForkLevelReached();

        this.onForkLevelReached = null;
      }
    });

    AssetsService.registerDisposeCallback(this.fork, () => {
      TimeService.disposeFrameListener(frameListener);
    });

    MathService.releaseVec3(forkSize);
    UtilsService.releaseBox3(box3);
  }

  setForkLevel(level, onForkLevelReached = null) {
    const forkliftPosition = MathService.getVec3();
    this.target.getWorldPosition(forkliftPosition);

    this.forkLevel = MathUtils.clamp(level - forkliftPosition.y - 0.5, -0.5, 1.5);
    this.onForkLevelReached = onForkLevelReached;
  }

  pickUpItem(item) {
    if (this.heldItem) {
      return;
    }

    this.cutscene = true;
    this.heldItem = item;

    const itemPosition = MathService.getVec3();
    item.target.getWorldPosition(itemPosition);

    this.setForkLevel(itemPosition.y, () => {
      item.stickTo(this.fork);
    
      setTimeout(() => {
        this.cutscene = false;
      }, 100);
    });

    MathService.releaseVec3(itemPosition);
  }

  placeItem(socket) {
    if (!this.heldItem) {
      return;
    }

    this.cutscene = true;

    const socketPosition = MathService.getVec3();
    socket.target.getWorldPosition(socketPosition);

    this.setForkLevel(socketPosition.y, () => {
      this.heldItem.stickTo(socket.target);

      this.heldItem = null;
    
      setTimeout(() => {
        this.cutscene = false;
      }, 100);
    });

    MathService.releaseVec3(socketPosition);
  }

  die() {
    if (this.isDead) {
      return;
    }

    this.isDead = true;

    AssetsService.getAudio(YouDiedSFX).then(audio => {
      AudioService.setAudioVolume(audio, 0.8);
      AudioService.playAudio(10, audio, false);
    });

    if (this.isPlayer) {
      const mock = new Three.Object3D();
      this.target.getWorldPosition(mock.position);
      this.target.getWorldQuaternion(mock.quaternion);

      this.target.parent.add(mock);

      CameraService.tween = 1.0;
      CameraService.followPivotPosition.set(15.0, 20.0, 0.0);
      CameraService.follow(mock);

      setTimeout(() => {
        VarService.setVar('playerDead', true);
      }, 2000);
    }

    WorldService.allowInteractions(false);

    this.physicsBody.angularDamping = 0.0;
    this.physicsBody.linearDamping = 0.0;

    new ExplosionVFX(this.target);

    this.physicsBody.applyLocalImpulse(
      new Cannon.Vec3(0.0, 20.0, -30.0),
      new Cannon.Vec3(0.0, 4.0, 2.0)
    );
  }
}