import { Three, Cannon, AssetsService, GameObjectClass, InteractionEnums, InteractionsService, MathService, PhysicsWrapper, RenderService, TimeService, UtilsService, PhysicsService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { HeartsVFX } from '../game-vfx/hearts-vfx';

export class BoxGameObject extends GameObjectClass {
  target = null;

  active = false;
  stickyTarget = null;

  heartsVfx = null;

  constructor(target) {
    super();
    
    this.target = target;

    this.onCreate();

    if (target.userData.boxId === 'hampster') {
      this.createHampsterInteractions();

      RenderService.getScene().add(target);
    }
  }

  createHampsterInteractions() {
    this.target.traverse(child => {
      if (child.userData.gameObject === 'heartsVfx') {
        this.heartsVfx = new HeartsVFX(child);
      }
    });
  }

  onCreate() {
    const target = this.target;

    target.userData.physicsWeight = 10.0;
    target.userData.physicsShape = 'box';

    WorldService.createPhysicalBody(target);

    const targetWorldPosition = new Three.Vector3();
    target.getWorldPosition(targetWorldPosition);

    WorldService.registerInteractionPoint(targetWorldPosition);
    AssetsService.registerDisposeCallback(target, () => WorldService.disposeInteractionPoint(targetWorldPosition));

    InteractionsService.registerListener(target, [ InteractionEnums.eventClick ], () => {
      const player = WorldService.getPlayer();

      if (!this.active || !player || this.stickyTarget) {
        return;
      }

      player.pickUpItem(this);
      WorldService.disposeInteractionPoint(targetWorldPosition);
    });

    const frameListener = TimeService.registerFrameListener(() => {
      if (!this.stickyTarget) {
        this.onAllowPickup();
      } else {
        this.onFollowSticky();
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      InteractionsService.disposeListener(this.target);
      TimeService.disposeFrameListener(frameListener);
    });
  }

  onFollowSticky() {
    const target = this.target;
    const stickyTarget = this.stickyTarget;

    this.active = false;

    const stickyPosition = MathService.getVec3();
    const stickyQuaternion = MathService.getQuaternion();

    const box3 = UtilsService.getBox3();

    const targetSize = MathService.getVec3();
    box3.expandByObject(target);
    box3.getSize(targetSize);

    stickyTarget.getWorldPosition(stickyPosition);
    stickyTarget.getWorldQuaternion(stickyQuaternion);

    stickyPosition.y += targetSize.y;

    target.position.lerp(stickyPosition, 1.0);
    target.quaternion.slerp(stickyQuaternion, 1.0);

    MathService.releaseVec3(stickyPosition);
    MathService.releaseVec3(targetSize);
    MathService.releaseQuaternion(stickyQuaternion);
    UtilsService.releaseBox3(box3);

    if (this.heartsVfx) {
      if (stickyTarget?.parent === WorldService.getPlayer()?.target) {
        const body = WorldService.getPlayer()?.physicsBody;

        if (body) {
          this.heartsVfx.active = body.velocity.length() >= 2.0;
        } else {
          this.heartsVfx.active = false;
        }
      } else {
        this.heartsVfx.active = false;
      }
    }
  }

  onAllowPickup() {
    const target = this.target;
    const player = WorldService.getPlayer();

    if (!player) {
      return;
    }

    const { target: playerForklift } = player;

    const forkliftPosition = MathService.getVec3();
    const boxPosition = MathService.getVec3();

    playerForklift.getWorldPosition(forkliftPosition);
    target.getWorldPosition(boxPosition);

    const distance = MathService.getVec2(
      forkliftPosition.x - boxPosition.x,
      forkliftPosition.z - boxPosition.z,
    );
    const distanceToForklift = distance.lengthSq();
    const distanceToGrab = Math.abs(forkliftPosition.y - boxPosition.y);
    const isWithinReach = distanceToForklift <= 15.0 && distanceToGrab < 2.0;

    const directionA = MathService.getVec3();
    const directionB = MathService.getVec3();
    playerForklift.getWorldDirection(directionA).normalize();
    directionA.y = 0.0;

    directionB.copy(boxPosition).sub(forkliftPosition).normalize();
    directionB.y = 0.0;

    const isFacingTarget = directionA.dot(directionB) >= 0.9;

    if (isWithinReach && isFacingTarget) {
      this.active = true;
    } else {
      this.active = false;
    }

    MathService.releaseVec3(forkliftPosition);
    MathService.releaseVec3(boxPosition);
    MathService.releaseVec3(directionA);
    MathService.releaseVec3(directionB);
    MathService.releaseVec2(distance);
  }

  stickTo(object) {
    WorldService.disablePhysicalBody(this.target);

    this.stickyTarget = object;
  }
}