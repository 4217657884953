import { Three, Cannon, AssetsService, createBoxHelper, InteractionEnums, InteractionsService, MathService, PhysicsService, PhysicsWrapper, RenderService, TimeService, UtilsService, VarService } from 'three-default-cube';
import { DebugHighlightShader } from '../shaders/debug-highlight';

const { MathUtils } = Three;

export const SHOW_COLLISION_BOXES = false;

class WorldServiceClass {
  physicsLoop = null;
  interactionPosition = new Three.Vector3();
  interactionActive = false;
  disableInteractions = false;

  player = null;
  playerCharacter = null;
  triggers = {};

  worldTiles = [];
  interactionPoints = [];

  reset() {
    TimeService.disposeFrameListener(this.physicsLoop);
    this.physicsLoop = null;

    this.player = null;
    this.playerCharacter = null;
    this.triggers = {};

    this.worldTiles = [];
  }

  setPlayer(target) {
    this.player = target;
  }

  setPlayerCharacter(target) {
    this.playerCharacter = target;
  }

  getPlayer() {
    return this.player;
  }

  getPlayerCharacter() {
    return this.playerCharacter;
  } 

  createPhysics() {
    this.disposePhysics();
  }

  startPhysics() {
    if (this.physicsLoop) {
      return;
    }
  }

  disposePhysics() {
    this.disableInteractions = false;
    this.interactionActive = false;

    PhysicsService.disposeAll();
  }

  createInteractions() {
    const scene = RenderService.getScene();

    const interactionPlane = new Three.Mesh(
      new Three.PlaneBufferGeometry(100.0, 100.0),
      new Three.MeshBasicMaterial({ color: 0xff00ff, side: Three.DoubleSide, opacity: 0.2, transparent: true })
    );
    interactionPlane.visible = false;
    interactionPlane.position.z -= 1.0;
    RenderService.getNativeCamera().add(interactionPlane);

    const interactionPreview = new Three.Mesh(
      new Three.SphereBufferGeometry(0.5, 32, 32),
      new Three.MeshNormalMaterial()
    );
    scene.add(interactionPreview);

    AssetsService.registerDisposable(interactionPlane);
    AssetsService.registerDisposable(interactionPreview);

    const raycaster = UtilsService.getRaycaster();

    InteractionsService.registerInvisibleListener(interactionPlane, InteractionEnums.eventHold, () => {
      if (this.disableInteractions) {
        return;
      }

      this.interactionActive = true;
    });

    InteractionsService.registerInvisibleListener(interactionPlane, InteractionEnums.eventRelease, () => {
      if (this.disableInteractions) {
        return;
      }

      this.interactionActive = false;
    });

    const frameListener = TimeService.registerFrameListener(() => {
      if (!this.interactionActive) {
        interactionPreview.visible = false;

        return;
      }

      interactionPreview.visible = false;

      raycaster.setFromCamera(InteractionsService.pointer, RenderService.getNativeCamera());

      const hits = raycaster.intersectObjects(PhysicsService.getNavmaps(), true);

      if (hits.length) {
        interactionPreview.position.copy(hits[0].point);
        this.interactionPosition.copy(hits[0].point);
      }
    });

    AssetsService.registerDisposeCallback(interactionPreview, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }

  disablePhysicalBody(target) {
    const physics = PhysicsService.getBodyFromObject(target);

    PhysicsService.makeStatic(physics);
  }

  createPhysicalBody(target) {
    return PhysicsService.registerBody(target, target.userData);
  }

  allowInteractions(allow = true) {
    this.disableInteractions = !allow;

    if (!allow) {
      this.interactionActive = false;
    }
  }

  connectBodies(bodyA, bodyB) {
    // return PhysicsService.registerConstraint({
    //   userData: {
    //     cannonRef: bodyA
    //   }
    // }, {
    //   userData: {
    //     cannonRef: bodyB
    //   }
    // }, 1.0);
  }

  disconnectBody(body) {
    return PhysicsService.disposeConstraintOnBody(body);
  }

  addWorldTile(tile) {
    this.worldTiles.push(tile);
  }

  disposeAllExceptActiveWorldTile() {
    if (this.worldTiles.length > 2) {
      AssetsService.disposeAsset(this.worldTiles.shift());
    }
  }

  disposeWorldTiles() {
    this.worldTiles.forEach(tile => {
      AssetsService.registerDisposable(tile);
    });
  }

  registerInteractionPoint(point) {
    this.disposeInteractionPoint(point);

    this.interactionPoints.push(point);
  }

  disposeInteractionPoint(point) {
    this.interactionPoints = this.interactionPoints.filter(match => !match.equals(point));
  }
}

export const WorldService = new WorldServiceClass();