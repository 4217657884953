import { Three, AssetsService, GameObjectClass, TimeService } from 'three-default-cube';

export class ElectricityGameObject extends GameObjectClass {
  target = null;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  onCreate() {
    const target = this.target;
    let material = null;

    target.traverse(child => {
      if (child.material) {
        if (!material) {
          material = child.material;
    
          material.roughness = 1.0;
          material.metalness = 0.0;
          material.emissiveIntensity = 10.0;
          material.emissive = new Three.Color(0xffcccc);
          material.opacity = 0.5;
          material.castShadow = false;
          material.receiveShadow = false;
        }
    
        child.material = material;
      }
    });
    
    const frameListener = TimeService.registerFrameListener(({ elapsedTime }) => {
      material.map.offset.set(-Math.round(elapsedTime / 0.1) * 0.2, 0.0);
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }
}
