import { AssetsService, AudioService, GameObjectClass, MathUtils, TimeService } from 'three-default-cube';
import { triggersMet } from '../utils/triggers';

import EngineSFX from '../assets/audio/engine-1.mp3';

export class EngineGameObject extends GameObjectClass {
  target = null;

  audio = null;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  onCreate() {
    AssetsService.getAudio(EngineSFX).then(audio => {
      this.audio = audio;
    
      AudioService.setAudioVolume(audio, 0.0);
      AudioService.playAudio(21, audio, true);
    });

    const { triggerId } = this.target.userData;
    const originalPosition = this.target.position.clone();
    const originalRotation = this.target.rotation.clone();

    const frameListener = TimeService.registerFrameListener(() => {
      const triggerEnabled = triggersMet(triggerId);

      if (triggerEnabled) {
        this.target.position.y = originalPosition.y + Math.random() * 0.1;
        this.target.rotation.x = originalRotation.x + Math.random() * 0.05 - 0.025;

        AudioService.setAudioVolume(this.audio, 0.3);
      } else {
        this.target.position.y = originalPosition.y;

        AudioService.setAudioVolume(this.audio, 0.0);
      }
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }
}