import { Three, AssetsService, AudioChannelEnums, AudioService, CameraService, defaultTo, RenderService, TimeService, VarService } from 'three-default-cube/three-default-cube';
import { WorldService } from './world-service';

import DialogueNextSFX from '../assets/audio/dialogue-next.mp3';
import Speech0SFX from '../assets/audio/speech-0.mp3';
import EndingThemeMusic from '../assets/audio/ending-theme.mp3';

export const DISABLE_CUTSCENES = 0;

const CutsceneEnum = {
  Continue: 1,
  Exit: 2
};

const cutscenes = {
  introWelcome: [
    {
      type: 'camera',
      cutsceneTargetId: 'introSpeaker'
    },
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'Attention!',
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Forklift urgently needed',
        'in Zone 1.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Someone please move to',
        'Zone 1, thanks.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  introFinish: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'camera',
      cutsceneTargetId: 'introSpeakerEnd'
    },
    {
      type: 'dialogue',
      text: [
        'Ah, our new forklift',
        'operator...'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Operator Rago!'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Congratulations.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'You have been chosen',
        'to move to Zone 1.'
      ]
    },
    {
      type: 'camera',
      cutsceneTargetId: 'introMap'
    },
    {
      type: 'delay',
      delay: 1000
    },
    {
      type: 'trigger',
      triggerId: 'introMapFinalBillboard'
    },
    {
      type: 'delay',
      delay: 500
    },
    {
      type: 'dialogue',
      text: [
        'Just follow the path',
        'I have marked for you',
        'on the map.'
      ]
    },
    {
      type: 'delay',
      delay: 1000
    },
    {
      type: 'camera'
    }
  ],
  level1Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level1SpeakerEnd'
    },
    {
      type: 'dialogue',
      text: [
        'Congratulations on',
        'passing the first',
        'step, Rago.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'You should know...'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'the cargo we store in',
        'the boxes is very',
        'valuable.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'I do hope nothing bad',
        'happened to the boxes',
        'you just safely'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'drove around and',
        'did not trash',
        'with your forklift.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  level2Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level2SpeakerEnd'
    },
    {
      type: 'dialogue',
      text: [
        'You are doing a',
        'great job, Rago.',
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Truly oustanding.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'I am proud of you.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  level3Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level3SpeakerEnd'
    },
    {
      type: 'dialogue',
      text: [
        'Your handling of',
        'valuable cargo will',
        'surely'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'go down in history,',
        'Rago.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'The corridor to Zone 1',
        'stands wide open ahead',
        'of you.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  level4Zone1: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level4Zone1Sign'
    },
    {
      type: 'delay',
      delay: 1000
    },
    {
      type: 'dialogue',
      text: [
        'Would you look at',
        'these inconveniently',
        'placed obstructions.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Rago, you need to take',
        'a route around through',
        'the corporate offices.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Just ahead and to the',
        'left.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  level4Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'Offices should be empty',
        'at night.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'So just don\'t make',
        'too much of a mess.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Nobody will even notice',
        'you have been there.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  level5Hampster: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level5HampsterTarget'
    },
    {
      type: 'dialogue',
      text: [
        'Rago, I cannot help but',
        'notice...'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'but it seems like the',
        'corporate forgot about',
        'their hampster.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'We cannot just leave',
        'it here to perish, Rago.'
      ]
    },
    {
      type: 'delay',
      delay: 1500
    },
    {
      type: 'dialogue',
      text: [
        'Take it.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Take the hampster, Rago.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  level5Exit: [
    {
      type: 'code',
      code: () => {
        const player = WorldService.getPlayer();

        if (!player) {
          return false;
        }

        const { heldItem } = player;
        const heldItemId = heldItem?.target?.userData?.boxId;
        if (heldItemId !== 'hampster') {
          return CutsceneEnum.Continue;
        } else {
          WorldService.triggers['end'] = true;

          return CutsceneEnum.Exit;
        }
      }
    },
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'Rago, I cannot help but',
        'notice'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'that you are attempting',
        'to leave the hampster',
        'here to die.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'I\'m sorry Rago, but I\'m',
        'afraid I cannot let you',
        'do that.'
      ]
    },
    {
      type: 'camera'
    },
    {
      type: 'trigger',
      triggerId: 'cutscene_level5Exit',
      state: false
    }
  ],
  level5DoorNoHampster: [
    {
      type: 'code',
      code: () => {
        const player = WorldService.getPlayer();

        if (!player) {
          return false;
        }

        const { heldItem } = player;
        const heldItemId = heldItem?.target?.userData?.boxId;

        if (heldItemId === 'hampster') {
          return CutsceneEnum.Exit;
        } else {
          return CutsceneEnum.Continue;
        }
      }
    },
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'I have a feeling you are',
        'forgetting something.'
      ]
    },
  ],
  level7Intro: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'What a cheerful, little',
        'kitchen full of useful',
        'appliances.'
      ]
    }
  ],
  level7Microwave: [
    {
      type: 'delay',
      delay: 2000
    },
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'delay',
      delay: 1000
    },
    {
      type: 'dialogue',
      text: [
        'Rago, I cannot help but',
        'notice...'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'But it seems like for',
        'some reason, corporate',
        'placed the door switch'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'inside their microwave.'
      ]
    }
  ],
  level7Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'It had to be done, Rago.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Their lifespan is 2-3 days',
        'either way.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'I\'m sure there won\'t',
        'be anything ahead to',
        'remind you of'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'the good times you\'ve',
        'spent together.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Let the hampster go and',
        'move on to Zone 1.'
      ]
    },
    {
      type: 'camera'
    },
  ],
  level8Intro: [
    {
      type: 'camera',
      cutsceneTargetId: 'level8IntroTarget'
    },
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'I have given it a second',
        'thought.'
      ]
    },
    {
      type: 'trigger',
      triggerId: 'billboard1',
    },
    {
      type: 'dialogue',
      text: [
        'Maybe they do live a bit',
        'longer than 2-3 days.'
      ]
    },
    {
      type: 'camera'
    }
  ],
  level8Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'Look.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'I just pointed out.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'That there was a switch',
        'in that microwave.',
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Not saying you are a',
        'bad person.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Just could have pressed',
        'it with a cup or something,',
        'buddy.'
      ]
    },
  ],
  level10Intro: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'You are almost there now.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'Zone 1 is just down these',
        'stairs and through the',
        'corridor.'
      ]
    },
  ],
  level10Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'Ah, finally.'
      ]
    },
    {
      type: 'dialogue',
      text: [
        'After all this time.'
      ]
    }
  ],
  level11Ending: [
    {
      type: 'sound',
      sound: Speech0SFX
    },
    {
      type: 'dialogue',
      text: [
        'Huh...'
      ]
    },
    {
      type: 'delay',
      delay: 1000
    },
    {
      type: 'dialogue',
      text: [
        'Impossible.'
      ]
    },
    {
      type: 'delay',
      delay: 1000
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level11HampsterTarget',
      offsetX: 1.0,
      offsetY: 2.0,
      offsetZ: -5.0,
    },
    {
      type: 'delay',
      delay: 3000
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level11HampsterTarget',
      offsetX: 10.0,
      offsetY: 4.0,
      offsetZ: -10.0,
      onlyTween: true,
    },
    {
      type: 'delay',
      delay: 10000
    },
    {
      type: 'code',
      code: () => {
        AssetsService.getAudio(EndingThemeMusic).then(theme => {
          AudioService.playAudio(AudioChannelEnums.ambientChannel, theme, false);
          AudioService.setAudioVolume(theme, 0.5);          
        });

        return CutsceneEnum.Continue;
      }
    },
    {
      type: 'trigger',
      triggerId: 'credits',
    },
    {
      type: 'camera',
      cutsceneTargetId: 'level11Credits',
      offsetX: 0.0,
      offsetY: 0.0,
      offsetZ: 1.0,
    },
  ]
};

class CutsceneServiceClass {
  cutsceneTargets = {};

  activeDialogueInterval = null;
  activeDialoguePromise = null;

  reset() {
    this.cutsceneTargets = {};

    this.activeDialogueInterval = null;
    this.activeDialoguePromise = null;
  }

  registerCutsceneTarget(target) {
    this.cutsceneTargets[target.userData.cutsceneTargetId] = target;
  }

  startCutscene(id) {
    if (DISABLE_CUTSCENES) {
      return;
    }

    const player = WorldService.getPlayer();

    if (!player) {
      return;
    }

    player.cutscene = true;
    VarService.setVar('cutscene', true);

    const cutscene = cutscenes[id];

    if (!cutscene) {
      this.endCutscene();

      return;
    }

    this.parseStep(cutscene, 0);
  }

  parseStep(cutscene, step) {
    const nextStep = cutscene[step];

    if (nextStep) {
      let promised;

      switch (nextStep.type) {
        case 'dialogue':
          promised = this.cutsceneDialogue(nextStep);
          break;
        case 'camera':
          promised = this.cutsceneCamera(nextStep);
          break;
        case 'sound':
          promised = this.cutsceneSound(nextStep);
          break;
        case 'trigger':
          promised = this.cutsceneTrigger(nextStep);
          break;
        case 'delay':
          promised = this.cutsceneDelay(nextStep);
          break;
        case 'code':
          promised = this.cutsceneCode(nextStep);
          break;
      }

      if (!promised) {
        this.endCutscene();
      } else {
        promised.then(() => this.parseStep(cutscene, step + 1));
      }
    } else {
      this.endCutscene();
    }
  }

  endCutscene() {
    const player = WorldService.getPlayer();

    if (!player) {
      return;
    }

    VarService.setVar('cutscene', false);

    player.cutscene = false;
  }

  cutsceneDialogue({ text }) {
    let step = 0;
    const textTotal = [].concat(text).join('\n');

    if (this.activeDialogueInterval) {
      clearTimeout(this.activeDialogueInterval);
    }

    return new Promise(resolve => {
      const drawNextLetter = () => {
        this.activeDialogueInterval = setTimeout(() => {
          VarService.setVar('dialogueText', textTotal.substr(0, ++step));

          if (step < textTotal.length + 1) {
            drawNextLetter();
          } else {
            clearTimeout(this.activeDialogueInterval);
            this.activeDialogueInterval = null;
          }
        }, 100);
      };

      drawNextLetter();

      this.activeDialoguePromise = () => {
        if (this.activeDialogueInterval) {
          step = textTotal.length + 1;

          drawNextLetter();
        } else {
          VarService.setVar('dialogueText', '');

          clearTimeout(this.activeDialogueInterval);
          this.activeDialogueInterval = null;
          this.activeDialoguePromise = null;

          AssetsService.getAudio(DialogueNextSFX).then(audio => {
            AudioService.playAudio(AudioChannelEnums.globalChannel, audio);
          });

          setTimeout(() => {
            resolve();
          }, 300);
        }
      };
    });
  }

  cutsceneCamera({ cutsceneTargetId, onlyTween, offsetX, offsetY, offsetZ }) {
    return new Promise(resolve => {
      if (onlyTween) {
        CameraService.tween = 0.01;
        // CameraService.followPivotPosition.set(defaultTo(offsetX, 10.0), defaultTo(offsetY, 5.0), defaultTo(offsetZ, -5.0));

        return resolve();
      }

      const target = this.cutsceneTargets[cutsceneTargetId];

      if (!target) {
        CameraService.tween = 1.0;
        WorldService.getPlayer().follow();
        
        return resolve();
      }

      CameraService.tween = 0.9;

      CameraService.useThirdPersonCamera(
        target,
        new Three.Vector3(15.0, 20.0, 0.0),
        false
      );

      setTimeout(() => {
        resolve();
      }, 300);
    });
  }

  cutsceneSound({ sound }) {
    return new Promise(resolve => {
      AssetsService.getAudio(sound).then(audio => {
        AudioService.setAudioVolume(audio, 0.4);
        AudioService.playAudio(14, audio, false);

        resolve();
      });
    });
  }

  cutsceneDelay({ delay }) {
    return new Promise(resolve => {
      setTimeout(() => resolve(), delay || 0.0);
    });
  }

  cutsceneTrigger({ triggerId, state }) {
    return new Promise(resolve => {
      WorldService.triggers[triggerId] = typeof state !== 'undefined' ? state : true;

      resolve();
    });
  }

  cutsceneCode({ code }) {
    const status = code();

    if (status === CutsceneEnum.Continue) {
      return Promise.resolve();
    } else {
      return null;
    }
  }
}

export const CutsceneService = new CutsceneServiceClass();
