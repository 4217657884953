import { WorldService } from "../services/world-service";

export const triggersMet = (triggersList, triggersOrder) => {
  if (typeof triggersList === 'undefined' || triggersList.length === 0) {
    return true;
  }

  let list;

  if (typeof triggersList === 'string') {
    try {
      list = JSON.parse(triggersList);
    } catch {
      list = triggersList;
    }

    if (triggersList.startsWith('[') && triggersList.endsWith(']')) {
      list = triggersList.substr(1, triggersList.length - 2).split(',').map(item => `${item}`.trim());
    }
  } else {
    list = triggersList;
  }

  if (typeof triggersOrder !== 'undefined') {
    let wrongOrder = false;
    let triggersCount = 0;

    const result = [].concat(triggersOrder).reduce((previous, id) => {
      const current = WorldService.triggers[id] === true;

      if (wrongOrder === false) {
        wrongOrder = previous === false && current === true;
      }

      if (current) {
        triggersCount++;
      }

      return previous && current;
    }, true);

    if (wrongOrder && triggersCount >= 2) {
      [].concat(triggersOrder).forEach(id => {
        WorldService.triggers[id] = false;
      });
    }

    return result;
  }
  
  return !([].concat(list).find(id => WorldService.triggers[id] !== true));
};
