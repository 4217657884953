import { Three, AssetsService, TimeService } from "three-default-cube/three-default-cube";
import { WorldService } from "../services/world-service";

export class LavaSurface {
  triggerTimer = null;

  constructor(target) {
    this.target = target;

    this.onCreate();
  }

  onCreate() {
    let lavaMaterial = null;

    this.target.traverse(child => {
      if (child.material) {
        if (!lavaMaterial) {
          lavaMaterial = AssetsService.cloneMaterial(child.material);
        }

        child.material = lavaMaterial;
      }
    });

    lavaMaterial.map = AssetsService.cloneTexture(lavaMaterial.map);
    lavaMaterial.map.needsUpdate = true;
    lavaMaterial.metalness = 1.0;
    lavaMaterial.roughness = 1.0;
    lavaMaterial.emissiveMap = lavaMaterial.map;
    lavaMaterial.emissive = new Three.Color(0xffffff);
    lavaMaterial.emissiveIntensity = 2.0;

    const offsetX = 0.0;
    const offsetY = 0.0;

    lavaMaterial.map.offset.set(offsetX, offsetY);

    const frameListener = TimeService.registerFrameListener(({ elapsedTime }) => {
      if (!lavaMaterial) {
        return;
      }

      const positionX = Math.round(((offsetX + elapsedTime) * 10000) / 6250) * 0.01;
      const positionY = Math.round(((offsetY + elapsedTime) * 10000) / 3000) * 0.01;

      lavaMaterial.map.offset.set(positionX, positionY);
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }

  onEnter({ body }) {
    if (body.target === WorldService.getPlayer()?.target) {
      this.triggerTimer = setTimeout(() => {
        WorldService.getPlayer().die();
      }, 300);
    }
  }

  onLeave({ body }) {
    if (body.target === WorldService.getPlayer()?.target) {
      this.triggerTimer = clearTimeout(this.triggerTimer);
    }
  }
}
