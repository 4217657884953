import { Three, Cannon, AssetsService, AudioService, GameObjectClass, InteractionEnums, InteractionsService, mathPi2, mathPi4, MathService, MathUtils, TimeService, UtilsService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { triggersMet } from '../utils/triggers';
import { ExplosionVFX } from '../game-vfx/explosion-vfx';
import ExplosionSFX from '../assets/audio/explosion-1.mp3';

export class MicrowaveGameObject extends GameObjectClass {
  target = null;
  door = null;
  socket = null;

  open = false;

  socketMaterial = null;
  active = false;

  constructor(target) {
    super();

    // NOTE Placing boxes displaces them by 50% on y-axis and I'm lazy
    const pivot = new Three.Group();

    const box3 = UtilsService.getBox3();

    const targetSize = MathService.getVec3();
    box3.expandByObject(target);
    box3.getSize(targetSize);

    pivot.userData = target.userData;
    pivot.position.copy(target.position);
    pivot.position.y -= targetSize.y / 2.0;
    pivot.quaternion.copy(target.quaternion);

    target.position.set(0.0, targetSize.y / 2.0, 0.0);
    target.quaternion.identity();

    target.parent.add(pivot);
    pivot.add(target);
    
    this.target = pivot;

    target.traverse(child => {
      if (child.userData.gameObject === 'microwaveDoor') {
        this.door = child;
      }

      if (child.userData.gameObject === 'microwaveSocket') {
        this.socket = child;
      }
    });

    this.onCreate();

    AssetsService.registerDisposable(pivot);
    MathService.releaseVec3(targetSize);
    UtilsService.releaseBox3(box3);
  }

  onCreate() {
    this.target.userData.physicsWeight = 0.1;
    this.target.userData.physicsShape = 'box';

    const originalChildren = this.target.children;
    this.target.children = [];
    const physicsBody = WorldService.createPhysicalBody(this.target);
    this.target.children = originalChildren;

    const target = this.socket;
    const { socketId } = target.userData;

    this.socketMaterial = new Three.MeshBasicMaterial({
      color: 0xffff33,
      opacity: 0.5,
      transparent: true
    });

    target.traverse(child => {
      if (child.material) {
        child.material = this.socketMaterial;
      }
    });

    InteractionsService.registerListener(target, [ InteractionEnums.eventClick ], () => {
      const player = WorldService.getPlayer();

      if (!this.active || !player || !player.heldItem || !this.open) {
        return;
      }

      const holdsMatchingItem = [].concat(target.userData.socketId).includes(player.heldItem.target.userData.boxId);

      if (holdsMatchingItem) {
        player.placeItem({ target: this.socket });

        target.visible = false;

        setTimeout(() => {
          WorldService.triggers[socketId] = true;
          this.open = false;

          setTimeout(() => {
            AssetsService.getAudio(ExplosionSFX).then(audio => {
              AudioService.setAudioVolume(audio, 0.8);
              AudioService.playAudio(20, audio, false);
            });

            new ExplosionVFX(this.target);
            physicsBody.applyImpulse(new Cannon.Vec3(2.0, 5.0, 0.0));
            physicsBody.angularVelocity = new Cannon.Vec3(3.0, 3.0, 3.0);
          }, 2000);
        }, 2000);
      }
    });

    const frameListener = TimeService.registerFrameListener(({ elapsedTime }) => {
      this.onAllowPlacement({ elapsedTime });

      if (this.door) {
        this.door.rotation.y = MathUtils.lerp(this.door.rotation.y, this.open ? -mathPi2 : 0.0, 0.1);
      }

      if (!this.open && !WorldService.triggers[socketId]) {
        this.open = triggersMet(this.target.userData.triggerId);
      }
    });

    AssetsService.registerDisposeCallback(target, () => {
      InteractionsService.disposeListener(target);
      TimeService.disposeFrameListener(frameListener);
    });
  }

  onAllowPlacement({ elapsedTime }) {
    const target = this.socket;
    const player = WorldService.getPlayer();

    if (!player || !this.open) {
      return;
    }

    const { target: playerForklift, heldItem } = player;

    if (!heldItem) {
      this.active = false;
      this.socketMaterial.opacity = 0.5;

      return;
    }

    const holdsMatchingItem = [].concat(target.userData.socketId).includes(heldItem.target.userData.boxId);

    if (!holdsMatchingItem) {
      this.active = false;
      this.socketMaterial.opacity = 0.5;

      return;
    }

    const forkliftPosition = MathService.getVec3();
    const boxPosition = MathService.getVec3();

    playerForklift.getWorldPosition(forkliftPosition);
    target.getWorldPosition(boxPosition);

    const distance = MathService.getVec2(
      forkliftPosition.x - boxPosition.x,
      forkliftPosition.z - boxPosition.z,
    );
    const distanceToForklift = distance.lengthSq();
    const distanceToGrab = Math.abs(forkliftPosition.y - boxPosition.y);
    const isWithinReach = distanceToForklift <= 20.0 && distanceToGrab < 2.0;

    const directionA = MathService.getVec3();
    const directionB = MathService.getVec3();
    playerForklift.getWorldDirection(directionA).normalize();
    directionA.y = 0.0;

    directionB.copy(boxPosition).sub(forkliftPosition).normalize();
    directionB.y = 0.0;

    const isFacingTarget = directionA.dot(directionB) >= 0.9;

    if (isWithinReach && isFacingTarget) {
      this.active = true;
      this.socketMaterial.opacity = (Math.sin(elapsedTime * 2.0) * 0.5 + 0.5) * 0.9 + 0.1;
    } else {
      this.active = false;
      this.socketMaterial.opacity = 0.5;
    }

    MathService.releaseVec3(forkliftPosition);
    MathService.releaseVec3(boxPosition);
    MathService.releaseVec3(directionA);
    MathService.releaseVec3(directionB);
    MathService.releaseVec2(distance);
  }
}