import { Three, AssetsService, AudioService, createArrowHelper, GameObjectClass, mathPi2, MathService, MathUtils, TimeService } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { triggersMet } from '../utils/triggers';

export class HampsterGameObject extends GameObjectClass {
  target = null;

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  onCreate() {
    const targetPosition = new Three.Vector3(0.0, 0.0, 0.0);
    let targetTimer = 0.0;
    const cageSpan = 0.7;

    this.target.position.copy(targetPosition);

    const targetWorldPosition = new Three.Vector3();
    this.target.getWorldPosition(targetWorldPosition);

    const pickNextPoint = () => {
      targetPosition.set(
        Math.random() * cageSpan - cageSpan / 2.0,
        -0.3,
        Math.random() * cageSpan - cageSpan / 2.0,
      );

      targetTimer = Math.random() * 3.0 + 0.1;
    };

    const frameListener = TimeService.registerFrameListener(({ dt }) => {
      const worldTarget = MathService.getVec3();

      const distance = this.target.position.distanceTo(targetPosition);

      if (distance < 0.1) {
        if (targetTimer <= 0.0) {
          pickNextPoint();
        } else {
          targetTimer -= dt;
        }
      } else {
        worldTarget.copy(targetPosition);
        this.target.parent.localToWorld(worldTarget);

        this.target.lookAt(worldTarget);
        this.target.position.lerp(targetPosition, 0.1);
      }

      MathService.releaseVec3(worldTarget);
    });

    AssetsService.registerDisposeCallback(this.target, () => {
      TimeService.disposeFrameListener(frameListener);
    });
  }
}
