import { GameObjectClass, MathService, TimeService, UtilsService, Three, convertMaterialType } from 'three-default-cube';
import { WorldService } from '../services/world-service';
import { correctMaterials } from '../utils/materials';

export class BobbleHeadGameObject extends GameObjectClass {
  target = null;

  physicsBody = null;
  physicsHead = null;

  randomDirection = new Three.Vector3(0.0, 0.0, 0.0);

  constructor(target) {
    super();

    this.target = target;

    this.onCreate();
  }

  setPlayerCharacter() {
    this.isPlayerCharacter = true;

    WorldService.setPlayerCharacter(this);
  }

  onCreate() {
    const target = this.target;

    const body = target;
    let head = null;

    target.traverse(child => {
      if (child.userData.gameObject === 'head') {
        head = child;
      }
    });

    const headPosition = MathService.getVec3();
    head.getWorldPosition(headPosition);

    body.position.set(0.0, 0.5, 0.0);
    body.rotateY(Math.PI);

    TimeService.registerFrameListener(() => {
      const interactionPosition = WorldService.interactionPosition.clone();

      const mock = UtilsService.getEmpty();
      head.parent.add(mock);
      mock.position.copy(head.position);
      mock.quaternion.copy(head.quaternion);

      if (Math.random() <= 0.05) {
        this.randomDirection.randomDirection();
      }

      const worldPosition = MathService.getVec3();
      head.getWorldPosition(worldPosition);

      interactionPosition.y = worldPosition.y;
      interactionPosition.add(this.randomDirection);
      mock.lookAt(interactionPosition);

      const originalTargetQuaternion = MathService.getQuaternion();
      originalTargetQuaternion.copy(head.quaternion);

      head.quaternion.slerp(mock.quaternion, 0.1);

      UtilsService.releaseEmpty(mock);
      MathService.releaseQuaternion(originalTargetQuaternion);
      MathService.releaseVec3(worldPosition);
    });

    if (WorldService.getPlayer()) {
      this.driveForklift(); 
    }

    correctMaterials(target);
  }

  driveForklift() {
    const player = WorldService.getPlayer();

    player.target.add(this.target);

    // const bodyPosition = MathService.getVec3(
    //   this.physicsBody.position.x,
    //   this.physicsBody.position.y,
    //   this.physicsBody.position.z,
    // );
    // const delta = MathService.getVec3(
    //   player.physicsBody.position.x,
    //   player.physicsBody.position.y,
    //   player.physicsBody.position.z,
    // ).sub(bodyPosition);

    // WorldService.connectBodies(player.physicsBody, this.physicsBody);

    // this.physicsBody.position.x += delta.x;
    // this.physicsBody.position.y += delta.y;
    // this.physicsBody.position.z += delta.z;
    // this.physicsHead.position.x += delta.x;
    // this.physicsHead.position.y += delta.y;
    // this.physicsHead.position.z += delta.z;

    // MathService.releaseVec3(bodyPosition);
    // MathService.releaseVec3(delta);
  }
}