import * as Three from "three";
import {
  ViewClass,
  AssetsService,
  RenderService,
  SceneService,
  AnimationService,
  ParserService,
  VarService,
  AiService,
  PhysicsService,
  CameraService,
  TimeService,
  InteractionsService,
  UiService,
  ParticleService,
  AudioService,
  UtilsService,
  AudioChannelEnums,
  convertMaterialType,
} from "three-default-cube";
import sceneModel from "../assets/models/game-scene.glb";
import { ForkliftGameObject } from "../game-objects/forklift";
import { WorldService } from "../services/world-service";
import { BoxGameObject } from "../game-objects/box";
import { BoxSocketGameObject } from "../game-objects/box-socket";
import { ElectricityGameObject } from "../game-objects/electricity";
import { WallTriggerGameObject } from "../game-objects/wall-trigger";
import { FloorTriggerGameObject } from "../game-objects/floor-trigger";
import { DoorGameObject } from "../game-objects/door";
import { LaserBeamGameObject } from "../game-objects/laser-beam";
import { ElevatorGameObject } from "../game-objects/elevator";
import { correctMaterials } from "../utils/materials";
import { mergeNavmaps, mergeObjects } from "../utils/optimisation";

import { UiGameObject } from "../game-objects/ui";
import { CutsceneService } from "../services/cutscene-service";
import LevelDebug from "../assets/models/level-debug.glb";
import LevelModel0 from "../assets/models/level-0.glb";
import LevelModel1 from "../assets/models/level-1.glb";
import LevelModel2 from "../assets/models/level-2.glb";
import LevelModel3 from "../assets/models/level-3.glb";
import LevelModel4 from "../assets/models/level-4.glb";
import LevelModel5 from "../assets/models/level-5.glb";
import LevelModel6 from "../assets/models/level-6.glb";
import LevelModel7 from "../assets/models/level-7.glb";
import LevelModel8 from "../assets/models/level-8.glb";
import LevelModel9 from "../assets/models/level-9.glb";
import LevelModel10 from "../assets/models/level-10.glb";
import LevelModel11 from "../assets/models/level-11.glb";

import AmbientPuzzleMusic from "../assets/audio/ambient-puzzle.mp3";
import { BillboardGameObject } from "../game-objects/billboard";
import { HampsterGameObject } from "../game-objects/hampster";
import { MicrowaveGameObject } from "../game-objects/microwave";
import { EngineGameObject } from "../game-objects/engine";
import { SpawnGameObject } from "../game-objects/spawn";
import { changeView } from "../utils/view";

const levelModels = {
  debug: LevelDebug,
  intro: LevelModel0,
  level1: LevelModel1,
  level2: LevelModel2,
  level3: LevelModel3,
  level4: LevelModel4,
  level5: LevelModel5,
  level6: LevelModel6,
  level7: LevelModel7,
  level8: LevelModel8,
  level9: LevelModel9,
  level10: LevelModel10,
  level11: LevelModel11,
};

export const USE_SCENE_LIGHTS = true;

export class GameView extends ViewClass {
  endTile = null;
  currentLevel = 0;

  tiles = [];

  async loadLevelTile(level) {
    VarService.setVar("isLoading", true);
    await new Promise((resolve) => setTimeout(() => resolve(), 3000));

    const tileModel = await AssetsService.getModel(levelModels[level]);
    const scene = RenderService.getScene();

    const tilePosition = new Three.Vector3(0.0, 0.0, 0.0);

    if (this.endTile) {
      this.endTile.getWorldPosition(tilePosition);
    }

    tileModel.children.forEach((child) => {
      child.position.add(tilePosition);
    });

    PhysicsService.registerNewStaticBody(tileModel);

    correctMaterials(tileModel);
    mergeNavmaps(tileModel);

    ParserService.parseModel({
      target: tileModel,
      gameObjects: {
        physics: (target) => {
          WorldService.createPhysicalBody(target);
        },
        box: (target) => {
          new BoxGameObject(target);
        },
        boxSocket: (target) => {
          new BoxSocketGameObject(target);
        },
        tree: (target) => {
          AnimationService.registerAnimation({
            target,
            randomSeed: Math.random() * 1000,
            onStep: ({ target, animationTime }) => {
              target.rotation.x = Math.sin(animationTime) * -0.05;
            },
          });
        },
        floorTrigger: (target) => {
          new FloorTriggerGameObject(target);
        },
        wallTrigger: (target) => {
          new WallTriggerGameObject(target);
        },
        door: (target) => {
          new DoorGameObject(target);
        },
        electricity: (target) => {
          new ElectricityGameObject(target);
        },
        laserBeam: (target) => {
          new LaserBeamGameObject(target);
        },
        elevator: (target) => {
          new ElevatorGameObject(target);
        },
        endTile: (target) => {
          target.visible = false;

          this.endTile = target;
        },
        cutsceneTarget: (target) => {
          target.visible = false;

          CutsceneService.registerCutsceneTarget(target);
        },
        speaker: (target) => {
          let active = false;

          VarService.getVar("dialogueText", (value) => {
            active = !!value;
          });

          const frameListener = TimeService.registerFrameListener(
            ({ elapsedTime }) => {
              if (active) {
                target.scale.setScalar(
                  Math.sin(elapsedTime * 20.0) * 0.02 + 1.0
                );
              } else {
                target.scale.setScalar(1.0);
              }
            }
          );

          AssetsService.registerDisposeCallback(target, () => {
            TimeService.disposeFrameListener(frameListener);
          });
        },
        billboard: (target) => {
          new BillboardGameObject(target);
        },
        hampster: (target) => {
          new HampsterGameObject(target);
        },
        microwave: (target) => {
          new MicrowaveGameObject(target);
        },
        engine: (target) => {
          new EngineGameObject(target);
        },
        spawn: (target) => {
          new SpawnGameObject(target);
        },
        computerScreen: (target) => {
          target.material = convertMaterialType(target.material, 'lambert');
          target.material.emissiveMap = target.material.map;
          target.material.emissiveIntensity = 1.0;
          target.material.emissiveMap.needsUpdate = true;

          TimeService.registerIntervalListener(() => {
            target.material.emissiveIntensity = Math.random() * 0.9 + 0.1;
          }, Math.random() * 2000 + 2000);
        }
      },
      actions: {
        menu: () => {
          changeView("menu");
        },
      },
      onCreate: () => {
        scene.add(tileModel);

        WorldService.addWorldTile(tileModel);
        WorldService.disposeAllExceptActiveWorldTile();
        
        mergeObjects(tileModel);

        PhysicsService.physicsWorld.clearForces();

        if (CameraService.getCamera("default")) {
          CameraService.useGameObjectCamera(CameraService.getCamera("default"));
        }

        setTimeout(() => {
          VarService.setVar("isLoading", false);

          scene.visible = true;
        }, 300);
      },
    });
  }

  onCreate() {
    const scene = RenderService.getScene();

    WorldService.reset();
    WorldService.createPhysics();
    WorldService.createInteractions();

    new UiGameObject();

    AssetsService.getAudio(AmbientPuzzleMusic).then((theme) => {
      AudioService.playAudio(AudioChannelEnums.ambientChannel, theme);

      VarService.getVar("cutscene", (value) => {
        if (value) {
          AudioService.setAudioVolume(theme, 0.1);
        } else {
          AudioService.setAudioVolume(theme, 0.2);
        }
      });
    });
    scene.visible = false;

    const hemisphereLight = new Three.HemisphereLight(0xbbbbff, 0x5555ff, 2.0);
    scene.add(hemisphereLight);

    this.loadLevelTile(VarService.getVar('lastLevel'));
    // this.loadLevelTile('intro');
    // this.loadLevelTile('level1');
    // this.loadLevelTile('level2');
    // this.loadLevelTile('level3');
    // this.loadLevelTile('level4');
    // this.loadLevelTile('level5');
    // this.loadLevelTile('level6');
    // this.loadLevelTile('level7');
    // this.loadLevelTile('level8');
    // this.loadLevelTile('level9');

    WorldService.startPhysics();

    VarService.getVar("loadNextLevel", (value) => {
      if (value && this.currentLevel !== value) {
        VarService.setVar("lastLevel", value);

        this.currentLevel = value;

        this.loadLevelTile(this.currentLevel);

        VarService.setVar("loadNextLevel", false);
      }
    });
  }

  onDispose() {
    WorldService.disposeWorldTiles();

    this.endTile = null;
  }
}
